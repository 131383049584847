/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npx generate-graphql-code <email> <password>`
====================================================
*/
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  /** Timezone formatted per the IANA Time Zone database (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones) */
  TZInfo: any;
  Date: any;
};

export type Address = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  crossStreet?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  cross_street?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: AddressKind;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressAttributes = {
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<AddressKind>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  crossStreet?: Maybe<Scalars['String']>;
  /** deprecated, please use the camelCase version */
  cross_street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  /** deprecated, please use the camelCase version */
  state_code?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  _destroy?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export enum AddressKind {
  Home = 'home',
  Work = 'work',
  Billing = 'billing',
  Other = 'other'
}

/** A feature flag that is enabled by percentage across all sessions, and not based on the current user */
export type AnonymousFlag = {
  /** True if the flag is enabled for this session, false otherwise */
  enabled: Scalars['Boolean'];
  /** The name of the feature flag */
  name: Scalars['String'];
};

/** A data structure that includes both a collection of flags, and an encoded collection of percentiles for each flag */
export type AnonymousFlagData = {
  /** An encoded token storing the client's flag data */
  clientToken: Scalars['String'];
  /** A collection of flags and their enabled/disabled status */
  flags: Array<AnonymousFlag>;
};

/** Autogenerated input type of ApplyTemplateAppointmentInventory */
export type ApplyTemplateAppointmentInventoryInput = {
  startTime: Scalars['DateTime'];
  providerId?: Maybe<Scalars['Int']>;
  templateVariantId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ApplyTemplateAppointmentInventory */
export type ApplyTemplateAppointmentInventoryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  inventories?: Maybe<Array<AppointmentInventory>>;
  success: Scalars['Boolean'];
};

export type Appointment = Remindable & AppointmentCommon & {
  /** The kind of appointment */
  appointmentType: AppointmentType;
  /** The user who approved the appointment */
  approvedBy?: Maybe<InternalUser>;
  /**
   * The user who approved the appointment
   * @deprecated Please use the camelCase version.
   */
  approved_by?: Maybe<InternalUser>;
  /** The time at which the patient arrived for the appointment */
  arrivedAt?: Maybe<Scalars['DateTime']>;
  /** The time at which the appointment was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /**
   * The time at which the appointment was cancelled
   * @deprecated Please use the camelCase version.
   */
  cancelled_at?: Maybe<Scalars['DateTime']>;
  /**
   * Comments about the appointment
   * @deprecated Please use the camelCase version.
   */
  comments?: Maybe<Scalars['String']>;
  /** The date time when the patient confirmed the appointment on their mobile phone */
  confirmedAt?: Maybe<Scalars['DateTime']>;
  /** The time at which the appointment record was created */
  createdAt: Scalars['DateTime'];
  /**
   * The time at which the appointment record was created
   * @deprecated Please use the camelCase version.
   */
  created_at: Scalars['DateTime'];
  /** The time at which the patient departed the office */
  departedAt?: Maybe<Scalars['DateTime']>;
  /** The duration of the appointment in minutes */
  duration?: Maybe<Scalars['Int']>;
  /** The time at which the appointment is scheduled to conclude */
  endAt: Scalars['DateTime'];
  /**
   * The time at which the appointment is scheduled to conclude
   * @deprecated Please use the camelCase version.
   */
  end_at: Scalars['DateTime'];
  /** id of appointment object */
  id: Scalars['ID'];
  /** If the visit charges have been reviewed */
  isBillingReviewed: Scalars['Boolean'];
  /** Whether or not the appointment type is a remote kind */
  isRemote: Scalars['Boolean'];
  /** The URL for the video visit join page */
  joinLink?: Maybe<Scalars['String']>;
  /** The most recent covid lab order if any associated with this appointment */
  mostRecentOpenCovidLabOrder?: Maybe<LabOrder>;
  /**
   * name for Remindable interface
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String'];
  /** start of appointment time */
  notifiableStatus?: Maybe<Scalars['String']>;
  /**
   * start of appointment time
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']>;
  /** title for Remindable object */
  notificationSubtitle?: Maybe<Scalars['String']>;
  /** title for Remindable object */
  notificationTitle: Scalars['String'];
  /**
   * title for Remindable object
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']>;
  /**
   * title for Remindable object
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String'];
  office?: Maybe<Office>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  patient?: Maybe<Patient>;
  /** A list of lab orders associated with the patient of this appointment that a phlebs can close in a quick visit. */
  phlebQuickVisitLabOrders?: Maybe<Array<LabOrder>>;
  /** A program enrollment associated with this appointment */
  programEnrollment?: Maybe<ProgramEnrollment>;
  provider?: Maybe<Provider>;
  /** The patient-reported reason for the appointment */
  reason: Scalars['String'];
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']>;
  /** The id for the remote video visit */
  remoteMeetingId?: Maybe<Scalars['String']>;
  /** The URL for the remote video visit */
  remoteMeetingLink?: Maybe<Scalars['String']>;
  /** The time at which the provider saw the patient */
  seenAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not we should send SMS appointment reminders */
  sendSms?: Maybe<Scalars['Boolean']>;
  /**
   * Whether or not we should send SMS appointment reminders
   * @deprecated Please use the camelCase version.
   */
  send_sms?: Maybe<Scalars['Boolean']>;
  /** The service ticket associated with this appointment */
  serviceTicket?: Maybe<ServiceTicket>;
  /** The ID of the Service Ticket for a given appointment. */
  serviceTicketId?: Maybe<Scalars['Int']>;
  /** The number to which we will send SMS appointment reminders */
  smsNumber?: Maybe<Scalars['String']>;
  /**
   * The number to which we will send SMS appointment reminders
   * @deprecated Please use the camelCase version.
   */
  sms_number?: Maybe<Scalars['String']>;
  /** weight for appointment notifications */
  sortWeight: Scalars['Int'];
  /**
   * weight for appointment notifications
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int'];
  /** The time at which the appointment is scheduled to start */
  startAt: Scalars['DateTime'];
  /**
   * The time at which the appointment is scheduled to start
   * @deprecated Please use the camelCase version.
   */
  start_at: Scalars['DateTime'];
  /** The state of the appointment (created, booked, confirmed, arrived, started, cancelled, seen, or no_show) */
  state?: Maybe<Scalars['String']>;
  /** The local timezone in which the appointment will take place */
  timezone?: Maybe<Scalars['String']>;
  /** The local IANA-formatted timezone in which the appointment will take place */
  timezoneTzinfo?: Maybe<Scalars['TZInfo']>;
  /** description for Remindable interface */
  universalLink: Scalars['String'];
  /**
   * description for Remindable interface
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String'];
  /** The time at which the appointment record was last updated */
  updatedAt: Scalars['DateTime'];
  /**
   * The time at which the appointment record was last updated
   * @deprecated Please use the camelCase version.
   */
  updated_at: Scalars['DateTime'];
  /** The visit summary for the appointment */
  visitSummary?: Maybe<Summary>;
  /** The visit summary for the appointment */
  visitSummaryId?: Maybe<Scalars['ID']>;
  /**
   * The intention of this field is to eventually communicate whether an
   * appointment is for an office, video, or chat visit. We don't have those
   * appointment types yet, so this field currently always returns 'office'.
   */
  visitType?: Maybe<Scalars['String']>;
  /**
   * The intention of this field is to eventually communicate whether an
   * appointment is for an office, video, or chat visit. We don't have those
   * appointment types yet, so this field currently always returns 'office'.
   * @deprecated Please use the camelCase version.
   */
  visit_type?: Maybe<Scalars['String']>;
};

export type AppointmentCancellationReason = {
  displayReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type AppointmentCommon = {
  /** id of appointment object */
  id: Scalars['ID'];
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Appointment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

export type AppointmentInventory = {
  blocked: Scalars['Boolean'];
  cancelledAppointments?: Maybe<AppointmentConnection>;
  /** How many people can book this inventory */
  capacity: Scalars['Int'];
  /** A comments about why the appointment slot is blocked */
  comments: Scalars['String'];
  /** How long the appointment will last in seconds */
  duration: Scalars['Int'];
  /** The time the appointment will end */
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** A description of the appointment slot */
  label?: Maybe<Scalars['String']>;
  numBookedAppointments: Scalars['Int'];
  officeId: Scalars['Int'];
  providerId: Scalars['Int'];
  /** The URL for the remote video visit */
  remoteMeetingLink?: Maybe<Scalars['String']>;
  /** The time the appointment will start */
  startAt: Scalars['DateTime'];
};


export type AppointmentInventoryCancelledAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AppointmentInventoryBlock = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for AppointmentInventory. */
export type AppointmentInventoryConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentInventoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AppointmentInventory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentInventoryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentInventory>;
};

export type AppointmentLength = {
  id: Scalars['ID'];
  length: Scalars['Int'];
};

export type AppointmentType = AppointmentTypeCommon & {
  /** The color associated with this appointment type */
  color: Scalars['String'];
  /** A description of this appointment type */
  description?: Maybe<Scalars['String']>;
  /** The name of the appointment type */
  displayName?: Maybe<Scalars['String']>;
  /** Length of appointment */
  displayedDuration?: Maybe<Scalars['Int']>;
  firstVisitAppointmentType?: Maybe<AppointmentType>;
  /** id of the AppointmentType object */
  id: Scalars['ID'];
  /** Whether this is a brief appointment */
  isBrief: Scalars['Boolean'];
  /** Whether this appointment is for new patients */
  isNewPatient?: Maybe<Scalars['Boolean']>;
  /** Whether this is a primary care appointment */
  isPrimary: Scalars['Boolean'];
  /** Whether this is a specialty care appointment */
  isSpecialty: Scalars['Boolean'];
  /** Whether this is an urgent care appointment */
  isUrgent: Scalars['Boolean'];
  /** A longer description of this appointment type */
  longDescription?: Maybe<Scalars['String']>;
  /** The internal name of this appointment type */
  name: Scalars['String'];
  position: Scalars['Int'];
  /** Whether this is a remote appointment */
  remote?: Maybe<Scalars['Boolean']>;
  /** Whether this appointment type requires a HIR authorization from the current member */
  requiresHirAuthorization: Scalars['Boolean'];
};

export type AppointmentTypeCommon = {
  /** id of the AppointmentType object */
  id: Scalars['ID'];
};

/** Autogenerated input type of ArchiveInsurance */
export type ArchiveInsuranceInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ArchiveInsurance */
export type ArchiveInsurancePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean'];
};

/** Interface for different assignees, typically a role or internal user */
export type Assignee = {
  displayName: Scalars['String'];
  normalizedId: Scalars['ID'];
};

/** Autogenerated input type of AttachDocumentToProcedure */
export type AttachDocumentToProcedureInput = {
  documentId: Scalars['ID'];
  procedureOriginType: ProcedureOriginType;
  procedureOriginId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AttachDocumentToProcedure */
export type AttachDocumentToProcedurePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AttachServiceTicketImproperlyClosedOrder */
export type AttachServiceTicketImproperlyClosedOrderInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AttachServiceTicketImproperlyClosedOrder */
export type AttachServiceTicketImproperlyClosedOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketImproperlyClosedOrder?: Maybe<ServiceTicketImproperlyClosedOrder>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AuthorizeConnectedDevices */
export type AuthorizeConnectedDevicesInput = {
  authorized?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AuthorizeConnectedDevices */
export type AuthorizeConnectedDevicesPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type B2bCompany = {
  activationCodeRetrievableViaEmail: Scalars['Boolean'];
  b2bEmailDomains?: Maybe<Array<Scalars['String']>>;
  customElistError?: Maybe<Scalars['String']>;
  displayElistError: Scalars['Boolean'];
  displayName: Scalars['String'];
  /** @deprecated Use verifyDependentInfo instead to determine if dependents should be verified against the e-list */
  hasEligibleDependents: Scalars['Boolean'];
  /** @deprecated Never used */
  hasEligibleEmployees: Scalars['Boolean'];
  id: Scalars['ID'];
  includesDependent: Scalars['Boolean'];
  name: Scalars['String'];
  verifyDependentInfo: Scalars['Boolean'];
};

/** Autogenerated input type of CancelServiceTicketTransmission */
export type CancelServiceTicketTransmissionInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelServiceTicketTransmission */
export type CancelServiceTicketTransmissionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  success: Scalars['Boolean'];
};

export type CareTeam = {
  pcp?: Maybe<Provider>;
  providersSeen?: Maybe<Array<Maybe<Provider>>>;
  /** @deprecated Please use the camelCase version. */
  providers_seen?: Maybe<Array<Maybe<Provider>>>;
  specialistsSeen?: Maybe<Array<Maybe<Specialist>>>;
  /** @deprecated Please use the camelCase version. */
  specialists_seen?: Maybe<Array<Maybe<Specialist>>>;
};

/** Autogenerated input type of ChargePatientForServiceTicket */
export type ChargePatientForServiceTicketInput = {
  serviceTicketId: Scalars['ID'];
  stripePaymentMethodId: Scalars['String'];
  amountCents: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ChargePatientForServiceTicket */
export type ChargePatientForServiceTicketPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean'];
};

export type CheckVersion = {
  message?: Maybe<Scalars['String']>;
  minimumSupportedVersion?: Maybe<Scalars['String']>;
  updateRequired: Scalars['Boolean'];
};

/** Autogenerated input type of CompleteOrdersAndSignNoteForAppointment */
export type CompleteOrdersAndSignNoteForAppointmentInput = {
  appointmentId: Scalars['ID'];
  labOrderIds?: Maybe<Array<Scalars['ID']>>;
  allowClosedTicket?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CompleteOrdersAndSignNoteForAppointment */
export type CompleteOrdersAndSignNoteForAppointmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  labOrders?: Maybe<Array<LabOrder>>;
  success: Scalars['Boolean'];
  summary?: Maybe<Summary>;
};

/** Autogenerated input type of CompleteProcedureInteraction */
export type CompleteProcedureInteractionInput = {
  id: Scalars['ID'];
  allowClosedTicket?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CompleteProcedureInteraction */
export type CompleteProcedureInteractionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

export type Contact = {
  acceptingNewPatients: Scalars['Boolean'];
  acceptsInsurance: Scalars['Boolean'];
  address?: Maybe<Address>;
  availability?: Maybe<Rating>;
  companyName?: Maybe<Scalars['String']>;
  contactInsurances?: Maybe<Array<Maybe<ContactInsurance>>>;
  createdAt: Scalars['DateTime'];
  customWarning?: Maybe<Scalars['String']>;
  degrees?: Maybe<Scalars['String']>;
  descendants?: Maybe<Array<Maybe<Contact>>>;
  diagnosticGroups?: Maybe<Array<Maybe<DiagnosticGroup>>>;
  displayName?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Email>>>;
  expertise?: Maybe<Rating>;
  firstName?: Maybe<Scalars['String']>;
  formerName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  hasPreferredAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  incompleteReason?: Maybe<Scalars['String']>;
  institutions?: Maybe<Array<Maybe<Institution>>>;
  insuranceNetworks?: Maybe<Array<Maybe<InsuranceNetwork>>>;
  insuranceNotes?: Maybe<Scalars['String']>;
  isComplete: Scalars['Boolean'];
  isCustom: Scalars['Boolean'];
  isElectronicallyIntegrated: Scalars['Boolean'];
  isInternal: Scalars['Boolean'];
  isPartner: Scalars['Boolean'];
  isQuality: Scalars['Boolean'];
  kind: ContactKind;
  languagesSpoken?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  noPhysicalLocation: Scalars['Boolean'];
  nonAcceptedContactInsurances?: Maybe<Array<Maybe<ContactInsurance>>>;
  npi?: Maybe<Scalars['String']>;
  officeHours?: Maybe<Scalars['String']>;
  otherNotes?: Maybe<Scalars['String']>;
  parent?: Maybe<Contact>;
  personality?: Maybe<Rating>;
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  specialties?: Maybe<Array<Maybe<Specialty>>>;
  status?: Maybe<ContactsStatus>;
  suffix?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<InternalUser>;
  website?: Maybe<Scalars['String']>;
};


export type ContactAddressArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactContactInsurancesArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactEmailsArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactHasPreferredAccessArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactInsuranceNetworksArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactInsuranceNotesArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactIsElectronicallyIntegratedArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactIsPartnerArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactIsQualityArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactNoPhysicalLocationArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactNonAcceptedContactInsurancesArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};


export type ContactPhoneNumbersArgs = {
  include_inherited_data?: Maybe<Scalars['Boolean']>;
  withInheritedData?: Maybe<Scalars['Boolean']>;
};

export type ContactInsurance = {
  id: Scalars['ID'];
  insuranceCarrier: InsuranceCarrier;
  insuranceTypes?: Maybe<Array<Maybe<InsuranceType>>>;
};

export type ContactInsuranceAttributes = {
  id?: Maybe<Scalars['ID']>;
  insuranceCarrierId?: Maybe<Scalars['ID']>;
  /** deprecated, please use the camelCase version */
  insurance_carrier_id?: Maybe<Scalars['ID']>;
  insuranceTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** deprecated, please use the camelCase version */
  insurance_type_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  _destroy?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export enum ContactKind {
  Generic = 'generic',
  Consultant = 'consultant',
  LabProvider = 'lab_provider',
  FromEmail = 'from_email',
  Specialist = 'specialist',
  Practice = 'practice',
  Group = 'group'
}

export type ContactsStatus = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of CreateAppointmentInventory */
export type CreateAppointmentInventoryInput = {
  duration: Scalars['Int'];
  startTime: Scalars['DateTime'];
  providerId?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAppointmentInventory */
export type CreateAppointmentInventoryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  inventory?: Maybe<AppointmentInventory>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  kind?: Maybe<ContactKind>;
  statusId?: Maybe<Scalars['ID']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  incompleteReason?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  formerName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  degrees?: Maybe<Scalars['String']>;
  languagesSpoken?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  officeHours?: Maybe<Scalars['String']>;
  availability?: Maybe<Rating>;
  expertise?: Maybe<Rating>;
  personality?: Maybe<Rating>;
  customWarning?: Maybe<Scalars['String']>;
  otherNotes?: Maybe<Scalars['String']>;
  noPhysicalLocation?: Maybe<Scalars['Boolean']>;
  acceptingNewPatients?: Maybe<Scalars['Boolean']>;
  acceptsInsurance?: Maybe<Scalars['Boolean']>;
  npi?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  contactInsurancesAttributes?: Maybe<Array<Maybe<ContactInsuranceAttributes>>>;
  nonAcceptedContactInsurancesAttributes?: Maybe<Array<Maybe<ContactInsuranceAttributes>>>;
  insuranceNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  insuranceNotes?: Maybe<Scalars['String']>;
  phoneNumbersAttributes?: Maybe<Array<Maybe<PhoneNumberAttributes>>>;
  emailsAttributes?: Maybe<Array<Maybe<EmailAttributes>>>;
  addressAttributes?: Maybe<AddressAttributes>;
  specialtyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  institutionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  diagnosticGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isInternal?: Maybe<Scalars['Boolean']>;
  isPartner?: Maybe<Scalars['Boolean']>;
  isQuality?: Maybe<Scalars['Boolean']>;
  isElectronicallyIntegrated?: Maybe<Scalars['Boolean']>;
  hasPreferredAccess?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateContact */
export type CreateContactPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateHelpRequest */
export type CreateHelpRequestInput = {
  helpflowType: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  actionUrl?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  triageNoteId?: Maybe<Scalars['Int']>;
  linkedNoteId?: Maybe<Scalars['Int']>;
  licensingBodyId?: Maybe<Scalars['ID']>;
  callerOnTheLine: Scalars['Boolean'];
  callbackName?: Maybe<Scalars['String']>;
  callbackNumber?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateHelpRequest */
export type CreateHelpRequestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  helpRequest?: Maybe<HelpRequest>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateInsurance */
export type CreateInsuranceInput = {
  copay?: Maybe<Scalars['Int']>;
  coinsurance?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  groupno: Scalars['String'];
  subscriberNumber?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['String']>;
  insuranceCarrierId?: Maybe<Scalars['ID']>;
  insuranceTypeId?: Maybe<Scalars['ID']>;
  insuranceNetworkId?: Maybe<Scalars['ID']>;
  insuranceRelationshipTypeId?: Maybe<Scalars['ID']>;
  holderFirstName?: Maybe<Scalars['String']>;
  holderLastName?: Maybe<Scalars['String']>;
  holderDob?: Maybe<Scalars['String']>;
  holderAddress1?: Maybe<Scalars['String']>;
  holderAddress2?: Maybe<Scalars['String']>;
  holderCity?: Maybe<Scalars['String']>;
  holderState?: Maybe<Scalars['String']>;
  holderZipCode?: Maybe<Scalars['String']>;
  holderPhoneNumber?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['ID']>;
  insuranceImage?: Maybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateInsurance */
export type CreateInsurancePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMassAppointmentCancellationRequest */
export type CreateMassAppointmentCancellationRequestInput = {
  appointmentIds: Array<Scalars['ID']>;
  appointmentCancellationReasonId: Scalars['ID'];
  deleteInventory?: Maybe<Scalars['Boolean']>;
  secureMessageBody?: Maybe<Scalars['String']>;
  secureMessageTitle?: Maybe<Scalars['String']>;
  sendEmailNotification?: Maybe<Scalars['Boolean']>;
  sendSecureMessage?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMassAppointmentCancellationRequest */
export type CreateMassAppointmentCancellationRequestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  massAppointmentChangeRequest?: Maybe<MassAppointmentChangeRequest>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMiscClericalNote */
export type CreateMiscClericalNoteInput = {
  patientId: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  emailSubject?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['Int']>;
  noteBodyText?: Maybe<Scalars['String']>;
  processingInstructions?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMiscClericalNote */
export type CreateMiscClericalNotePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  note?: Maybe<Note>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMlFeedbackProcedureSuggestion */
export type CreateMlFeedbackProcedureSuggestionInput = {
  mlBillingProcedureSuggestionId: Scalars['ID'];
  feedbackCategory: Scalars['String'];
  feedbackText?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMlFeedbackProcedureSuggestion */
export type CreateMlFeedbackProcedureSuggestionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureSuggestionFeedback?: Maybe<MlFeedbackProcedureSuggestion>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateOrUpdateMeasurementForProcedure */
export type CreateOrUpdateMeasurementForProcedureInput = {
  measurements: Array<ProcedureMeasurementInputObject>;
  procedureOriginType: ProcedureOriginType;
  procedureOriginId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateMeasurementForProcedure */
export type CreateOrUpdateMeasurementForProcedurePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean'];
};

export type CreatePrescriptionChangeRequestAttributes = {
  messageRequestCode: Scalars['String'];
  messageRequestSubcodes?: Maybe<Array<Scalars['String']>>;
  medicationOptions?: Maybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of CreatePrescriptionChangeRequests */
export type CreatePrescriptionChangeRequestsInput = {
  patientMedicationId: Scalars['ID'];
  createPrescriptionChangeRequestAttributes: CreatePrescriptionChangeRequestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePrescriptionChangeRequests */
export type CreatePrescriptionChangeRequestsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type CreatePrescriptionRenewalRequestAttributes = {
  patientMedicationId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePrescriptionRenewalRequests */
export type CreatePrescriptionRenewalRequestsInput = {
  patientPharmacyId: Scalars['ID'];
  createPrescriptionRenewalRequestAttributes: Array<CreatePrescriptionRenewalRequestAttributes>;
  patientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePrescriptionRenewalRequests */
export type CreatePrescriptionRenewalRequestsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateProcedureInteractionIndication */
export type CreateProcedureInteractionIndicationInput = {
  id: Scalars['ID'];
  problemTypeId: Scalars['ID'];
  problemCodeLocationId?: Maybe<Scalars['ID']>;
  problemCodeExtensionId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProcedureInteractionIndication */
export type CreateProcedureInteractionIndicationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  indication?: Maybe<Indication>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateProcedureInteraction */
export type CreateProcedureInteractionInput = {
  problemId?: Maybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  procedureSuggestionId?: Maybe<Scalars['ID']>;
  procedureTypeId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProcedureInteraction */
export type CreateProcedureInteractionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  stripeCustomerId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  setupIntentId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  /** deprecated, please use the camelCase version */
  name?: Maybe<Scalars['String']>;
  /** deprecated, please use the camelCase version */
  keywords?: Maybe<Scalars['String']>;
  taskTemplateId: Scalars['ID'];
  /** deprecated, please use the camelCase version */
  task_template_id?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  /** deprecated, please use the camelCase version */
  patient_id?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateTask */
export type CreateTaskPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of CreateToken */
export type CreateTokenInput = {
  grantType?: Maybe<Scalars['String']>;
  /** deprecated, please use the camelCase version */
  grant_type?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateToken */
export type CreateTokenPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateVideoCall */
export type CreateVideoCallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVideoCall */
export type CreateVideoCallPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  meetingId?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateVoluntaryAlignmentOffer */
export type CreateVoluntaryAlignmentOfferInput = {
  followUpAt?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  offerMethod?: Maybe<Scalars['String']>;
  offeredAt?: Maybe<Scalars['DateTime']>;
  offeredById?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  patientId: Scalars['ID'];
  documentIds?: Maybe<Array<Scalars['ID']>>;
  alignmentDocument?: Maybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVoluntaryAlignmentOffer */
export type CreateVoluntaryAlignmentOfferPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

export type Credentials = {
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};



/** Autogenerated input type of DebugServiceTicketTransmission */
export type DebugServiceTicketTransmissionInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DebugServiceTicketTransmission */
export type DebugServiceTicketTransmissionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteDocumentFromProcedure */
export type DeleteDocumentFromProcedureInput = {
  documentId: Scalars['ID'];
  procedureOriginId: Scalars['ID'];
  procedureOriginType: ProcedureOriginType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteDocumentFromProcedure */
export type DeleteDocumentFromProcedurePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteProcedureInteractionIndication */
export type DeleteProcedureInteractionIndicationInput = {
  id: Scalars['ID'];
  procedureInteractionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteProcedureInteractionIndication */
export type DeleteProcedureInteractionIndicationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteProcedureInteraction */
export type DeleteProcedureInteractionInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteProcedureInteraction */
export type DeleteProcedureInteractionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteServiceTicketItem */
export type DeleteServiceTicketItemInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteServiceTicketItem */
export type DeleteServiceTicketItemPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DetachStripePaymentMethod */
export type DetachStripePaymentMethodInput = {
  stripePaymentMethodId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DetachStripePaymentMethod */
export type DetachStripePaymentMethodPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type DiagnosticGroup = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DirectSignupError = {
  directSignupToken?: Maybe<DirectSignupToken>;
  message?: Maybe<Scalars['String']>;
};

export type DirectSignupToken = {
  email?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  signupComplete: Scalars['Boolean'];
};

/** Autogenerated input type of DismissMlBillingProcedureSuggestion */
export type DismissMlBillingProcedureSuggestionInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DismissMlBillingProcedureSuggestion */
export type DismissMlBillingProcedureSuggestionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureSuggestion?: Maybe<MlBillingProcedureSuggestion>;
  success: Scalars['Boolean'];
};

export type Document = {
  awsBucket: Scalars['String'];
  awsKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ext: Scalars['String'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  originalFilename: Scalars['String'];
  pdfBucket?: Maybe<Scalars['String']>;
  pdfKey?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Email = {
  displayEmail: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  display_email: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_preferred: Scalars['Boolean'];
  kind: EmailKind;
};

export type EmailAttributes = {
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<EmailKind>;
  email?: Maybe<Scalars['String']>;
  _destroy?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export enum EmailKind {
  Work = 'work',
  Personal = 'personal',
  Shared = 'shared',
  DirectMessage = 'direct_message',
  B2bEmployeeWork = 'b2b_employee_work',
  Other = 'other'
}

export type Encounter = {
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of EndVideoCall */
export type EndVideoCallInput = {
  meetingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EndVideoCall */
export type EndVideoCallPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ExportOrphanedOrders */
export type ExportOrphanedOrdersInput = {
  procedureTypeFilter?: Maybe<Scalars['String']>;
  officeFilter?: Maybe<Scalars['String']>;
  marketFilter?: Maybe<Scalars['String']>;
  providerIdFilter?: Maybe<Scalars['String']>;
  patientIdFilter?: Maybe<Scalars['String']>;
  dosFilter?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ExportOrphanedOrders */
export type ExportOrphanedOrdersPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type FeatureFlag = {
  enabled: Scalars['Boolean'];
  /** Whether or not the feature flag has been enabled for a group associated with a B2B company, by ID */
  enabledForCompany?: Maybe<Scalars['Boolean']>;
  /** Whether or not the feature flag has been enabled for a group associated with a discount code, by code */
  enabledForDiscountCode?: Maybe<Scalars['Boolean']>;
  /** The title of the feature flag */
  name: Scalars['String'];
};


export type FeatureFlagEnabledForCompanyArgs = {
  id: Scalars['Int'];
};


export type FeatureFlagEnabledForDiscountCodeArgs = {
  code: Scalars['String'];
};

export type Firebase = {
  namespace: Scalars['String'];
  token: Scalars['String'];
};

export type FloatMeasurementValidationRule = MeasurementValidationRule & {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  valueType: Scalars['String'];
};

export enum Gender {
  Male = 'male',
  Female = 'female',
  NonBinary = 'non_binary',
  Unknown = 'unknown'
}

export type GuestPlan = Plan & {
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type Hearabout = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HelpFlow = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type HelpRequest = {
  createdAt: Scalars['DateTime'];
  createdBy: InternalUser;
  description: Scalars['String'];
  id: Scalars['ID'];
  linkedNoteId: Scalars['Int'];
  patientId: Scalars['ID'];
  slackChannel: Scalars['String'];
  state: Scalars['String'];
};

export type Indication = {
  clinicalDescription?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  problemCode?: Maybe<ProblemCode>;
  problemCodeExtension?: Maybe<ProblemCodeExtension>;
  problemCodeLocation?: Maybe<ProblemCodeLocation>;
  problemType?: Maybe<ProblemType>;
  updatedAt: Scalars['DateTime'];
};

export type Institution = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Insurance = {
  cardImageS3KeyBack?: Maybe<Scalars['String']>;
  cardImageS3KeyFront?: Maybe<Scalars['String']>;
  cardUrlBack?: Maybe<Scalars['String']>;
  cardUrlFront?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  card_image_s3_key_back?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  card_image_s3_key_front?: Maybe<Scalars['String']>;
  coinsurance?: Maybe<Scalars['Int']>;
  /** @deprecated Please use the camelCase version. */
  comment?: Maybe<Scalars['String']>;
  copay?: Maybe<Scalars['Int']>;
  createdByMlOcr: Scalars['Boolean'];
  editedBy?: Maybe<InternalUser>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use the camelCase version. */
  groupno?: Maybe<Scalars['String']>;
  holderAddress1?: Maybe<Scalars['String']>;
  holderAddress2?: Maybe<Scalars['String']>;
  holderCity?: Maybe<Scalars['String']>;
  holderDob?: Maybe<Scalars['String']>;
  holderFirstName?: Maybe<Scalars['String']>;
  holderLastName?: Maybe<Scalars['String']>;
  holderPhoneNumber?: Maybe<Scalars['String']>;
  holderState?: Maybe<Scalars['String']>;
  holderZipCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceCarrier?: Maybe<InsuranceCarrier>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  insuranceRelationshipType: InsuranceRelationshipType;
  insuranceType?: Maybe<InsuranceType>;
  /** @deprecated Please use the camelCase version. */
  insurance_type?: Maybe<InsuranceType>;
  isArchived: Scalars['Boolean'];
  mlOcrNeedsHumanReview: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  opmEligibilityStatus?: Maybe<OpmEligibilityStatus>;
  opmEligibilityUpdatedAt?: Maybe<Scalars['DateTime']>;
  patient: Patient;
  planType?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  /** @deprecated Please use the camelCase version. */
  primary: Scalars['Boolean'];
  subscriberNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  subscriber_number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceCarrier = {
  id: Scalars['ID'];
  name: Scalars['String'];
  serviceAreas?: Maybe<Array<Maybe<ServiceArea>>>;
  /** @deprecated Please use the camelCase version. */
  service_areas?: Maybe<Array<Maybe<ServiceArea>>>;
};

export type InsuranceNetwork = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InsuranceRelationshipType = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InsuranceType = {
  id: Scalars['ID'];
  isSelfPay: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_self_pay: Scalars['Boolean'];
  name: Scalars['String'];
  networkRequired: Scalars['Boolean'];
};

export type IntegerMeasurementValidationRule = MeasurementValidationRule & {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  valueType: Scalars['String'];
};

export type InternalUser = Assignee & {
  additionalServiceAreas?: Maybe<Array<ServiceArea>>;
  dayTaskCount: Scalars['Int'];
  defaultOffice?: Maybe<Office>;
  defaultOfficeId?: Maybe<Scalars['ID']>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office_id?: Maybe<Scalars['ID']>;
  displayName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  display_name: Scalars['String'];
  email: Scalars['String'];
  homeOffice?: Maybe<Office>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  nonPreferredRoles?: Maybe<Array<Maybe<RoleType>>>;
  normalizedId: Scalars['ID'];
  preferences: InternalUserPreferences;
  preferredRole?: Maybe<RoleType>;
  /** @deprecated Please use the camelCase version. */
  preferred_role?: Maybe<RoleType>;
  prescribingCredentials?: Maybe<Array<Maybe<PrescribingCredential>>>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  serviceArea?: Maybe<ServiceArea>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  supportedB2bCompanies?: Maybe<Array<B2bCompany>>;
  weekTaskCount: Scalars['Int'];
};

/** The connection type for InternalUser. */
export type InternalUserConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InternalUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InternalUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InternalUserEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InternalUser>;
};

export type InternalUserPreferences = {
  id: Scalars['ID'];
  licensingBodies?: Maybe<Array<LicensingBody>>;
  videoNotificationEnabled?: Maybe<Scalars['Boolean']>;
};


export type LabInventory = {
  fluHighDose?: Maybe<LabInventoryItem>;
  fluRegular?: Maybe<LabInventoryItem>;
};

export type LabInventoryItem = {
  status?: Maybe<Scalars['String']>;
};

export type LabOrder = {
  /** The date the lab was collected. */
  collectionDate?: Maybe<Scalars['DateTime']>;
  /**
   * The date the lab was collected.
   * @deprecated Please use the camelCase version.
   */
  collection_date?: Maybe<Scalars['DateTime']>;
  dueOn: Scalars['DateTime'];
  id: Scalars['ID'];
  measurementOrderTypeSourceId?: Maybe<Scalars['ID']>;
  /** The provider who ordered the labs. */
  orderingProvider: Provider;
  /**
   * The provider who ordered the labs.
   * @deprecated Please use the camelCase version.
   */
  ordering_provider: Provider;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LegalDocument = {
  buttonHint?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  button_hint?: Maybe<Scalars['String']>;
  detailHint?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  detail_hint?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The text of the document */
  text: Scalars['String'];
  /** The document type */
  type: Scalars['String'];
  /** The document version */
  version: Scalars['String'];
};

export type LicensingBody = {
  id: Scalars['ID'];
  name: Scalars['String'];
  relativeRarityAsLicensingBody: Scalars['Float'];
  shortName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  short_name: Scalars['String'];
};

export type MassAppointmentCancellationRequest = MassAppointmentChangeRequest & {
  appointmentCancellationReason?: Maybe<AppointmentCancellationReason>;
  appointments: AppointmentConnection;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  id: Scalars['ID'];
  massAppointmentChangeRequestItems: MassAppointmentChangeRequestItemConnection;
};


export type MassAppointmentCancellationRequestAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MassAppointmentCancellationRequestMassAppointmentChangeRequestItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MassAppointmentCancellationRequestItem = MassAppointmentChangeRequestItem & {
  appointment: Appointment;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: MassAppointmentChangeRequestItemStatus;
};

export type MassAppointmentChangeRequest = {
  appointments: AppointmentConnection;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  massAppointmentChangeRequestItems: MassAppointmentChangeRequestItemConnection;
};


export type MassAppointmentChangeRequestAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MassAppointmentChangeRequestMassAppointmentChangeRequestItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum MassAppointmentChangeRequestChangeType {
  Cancellation = 'cancellation'
}

/** The connection type for MassAppointmentChangeRequest. */
export type MassAppointmentChangeRequestConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MassAppointmentChangeRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MassAppointmentChangeRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MassAppointmentChangeRequestEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MassAppointmentChangeRequest>;
};

export type MassAppointmentChangeRequestItem = {
  appointment: Appointment;
  id: Scalars['ID'];
  status: MassAppointmentChangeRequestItemStatus;
};

/** The connection type for MassAppointmentChangeRequestItem. */
export type MassAppointmentChangeRequestItemConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MassAppointmentChangeRequestItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MassAppointmentChangeRequestItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MassAppointmentChangeRequestItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MassAppointmentChangeRequestItem>;
};

export enum MassAppointmentChangeRequestItemStatus {
  Failed = 'failed',
  InProgress = 'in_progress',
  Succeeded = 'succeeded'
}

export type Measurement = {
  collectedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use the camelCase version. */
  collected_at?: Maybe<Scalars['DateTime']>;
  facilityNotes?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  facility_notes?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /**
   * The expected value for the result of the test. Either a value france for
   * numerical results or a string indicating an expected result (i.e. 'non
   * reactive' or 'not detected')
   */
  referenceRange?: Maybe<Scalars['String']>;
  /**
   * The expected value for the result of the test. Either a value france for
   * numerical results or a string indicating an expected result (i.e. 'non
   * reactive' or 'not detected')
   * @deprecated Please use the camelCase version.
   */
  reference_range?: Maybe<Scalars['String']>;
  /** The type of measurement. */
  type?: Maybe<Scalars['String']>;
  /** The id of the measurement type */
  typeId?: Maybe<Scalars['Int']>;
  /** The unit of measurement, either in metric or imperial, whichever applies. */
  unit: Scalars['String'];
  /** The result value of the test. Can be string or number. For example 'non reactive' or 78 */
  value: Scalars['String'];
  /** The type of the result of the test. */
  valueType: Scalars['String'];
  /**
   * The type of the result of the test.
   * @deprecated Please use the camelCase version.
   */
  value_type: Scalars['String'];
};

export type MeasurementOrderTypeSource = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MeasurementsValidationRules = {
  diastolicPressure: IntegerMeasurementValidationRule;
  heartRate: IntegerMeasurementValidationRule;
  respiratoryRate: IntegerMeasurementValidationRule;
  systolicPressure: IntegerMeasurementValidationRule;
  temperature: FloatMeasurementValidationRule;
};

export type MeasurementType = {
  defaultMeasurementUnit?: Maybe<MeasurementUnit>;
  defaultMetric: Scalars['Boolean'];
  id: Scalars['ID'];
  loincCode?: Maybe<Scalars['String']>;
  measurementTypeValueOptions?: Maybe<Array<MeasurementTypeValueOption>>;
  measurementValidationRange?: Maybe<MeasurementValidationRange>;
  name?: Maybe<Scalars['String']>;
};

/** The connection type for MeasurementType. */
export type MeasurementTypeConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MeasurementType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MeasurementTypeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MeasurementType>;
};

export type MeasurementTypeValueOption = {
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  label: Scalars['String'];
  sublabel?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MeasurementUnit = {
  id: Scalars['ID'];
  imperial?: Maybe<Scalars['String']>;
  metric: Scalars['String'];
};

export type MeasurementValidationRange = {
  id: Scalars['ID'];
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
};

/** Interface for common measurement validation rule fields */
export type MeasurementValidationRule = {
  valueType: Scalars['String'];
};

export type MedicationUnitOfMeasure = {
  abbr: Scalars['String'];
  abbrPlural: Scalars['String'];
  desc: Scalars['String'];
  descPlural: Scalars['String'];
  descPluralPref: Scalars['String'];
  descPref: Scalars['String'];
  id: Scalars['ID'];
};

export type MessageTemplate = {
  body: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MlBillingProcedureSuggestion = {
  cleanedInputText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  itemId?: Maybe<Scalars['Int']>;
  itemType?: Maybe<Scalars['String']>;
  predictiveWords?: Maybe<Scalars['String']>;
  probability?: Maybe<Scalars['Float']>;
  problemHistory: ProblemHistory;
  procedureType: Array<Maybe<ProcedureType>>;
  summaryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MlFeedbackProcedureSuggestion = {
  createdAt: Scalars['DateTime'];
  feedbackCategory: Scalars['String'];
  feedbackText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mlBillingProcedureSuggestionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type Mutation = {
  applyTemplateAppointmentInventory?: Maybe<ApplyTemplateAppointmentInventoryPayload>;
  archiveInsurance?: Maybe<ArchiveInsurancePayload>;
  attachDocumentToProcedure?: Maybe<AttachDocumentToProcedurePayload>;
  /** Attaches ServiceTicketImproperlyClosedOrder to a service ticket. */
  attachServiceTicketImproperlyClosedOrder?: Maybe<AttachServiceTicketImproperlyClosedOrderPayload>;
  authorizeConnectedDevices?: Maybe<AuthorizeConnectedDevicesPayload>;
  cancelServiceTicketTransmission?: Maybe<CancelServiceTicketTransmissionPayload>;
  chargePatientForServiceTicket?: Maybe<ChargePatientForServiceTicketPayload>;
  completeOrdersAndSignNoteForAppointment?: Maybe<CompleteOrdersAndSignNoteForAppointmentPayload>;
  completeProcedureInteraction?: Maybe<CompleteProcedureInteractionPayload>;
  createAppointmentInventory?: Maybe<CreateAppointmentInventoryPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createHelpRequest?: Maybe<CreateHelpRequestPayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createMassAppointmentCancellationRequest?: Maybe<CreateMassAppointmentCancellationRequestPayload>;
  createMiscClericalNote?: Maybe<CreateMiscClericalNotePayload>;
  createMlFeedbackProcedureSuggestion?: Maybe<CreateMlFeedbackProcedureSuggestionPayload>;
  createOrUpdateMeasurementForProcedure?: Maybe<CreateOrUpdateMeasurementForProcedurePayload>;
  createPrescriptionChangeRequests?: Maybe<CreatePrescriptionChangeRequestsPayload>;
  createPrescriptionRenewalRequests?: Maybe<CreatePrescriptionRenewalRequestsPayload>;
  createProcedureInteraction?: Maybe<CreateProcedureInteractionPayload>;
  createProcedureInteractionIndication?: Maybe<CreateProcedureInteractionIndicationPayload>;
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntentPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createToken?: Maybe<CreateTokenPayload>;
  createVideoCall?: Maybe<CreateVideoCallPayload>;
  createVoluntaryAlignmentOffer?: Maybe<CreateVoluntaryAlignmentOfferPayload>;
  /** @deprecated Please use the camelCase version. */
  create_prescription_renewal_requests?: Maybe<CreatePrescriptionRenewalRequestsPayload>;
  /** @deprecated Please use the camelCase version. */
  create_token?: Maybe<CreateTokenPayload>;
  debugServiceTicketTransmission?: Maybe<DebugServiceTicketTransmissionPayload>;
  deleteDocumentFromProcedure?: Maybe<DeleteDocumentFromProcedurePayload>;
  deleteProcedureInteraction?: Maybe<DeleteProcedureInteractionPayload>;
  deleteProcedureInteractionIndication?: Maybe<DeleteProcedureInteractionIndicationPayload>;
  deleteServiceTicketItem?: Maybe<DeleteServiceTicketItemPayload>;
  detachStripePaymentMethod?: Maybe<DetachStripePaymentMethodPayload>;
  dismissMlBillingProcedureSuggestion?: Maybe<DismissMlBillingProcedureSuggestionPayload>;
  endVideoCall?: Maybe<EndVideoCallPayload>;
  exportOrphanedOrders?: Maybe<ExportOrphanedOrdersPayload>;
  removeAppointmentInventories?: Maybe<RemoveAppointmentInventoriesPayload>;
  removeAppointmentInventory?: Maybe<RemoveAppointmentInventoryPayload>;
  resendDirectSignupEmail?: Maybe<ResendDirectSignupEmailPayload>;
  signLegalDocument?: Maybe<SignLegalDocumentPayload>;
  /** @deprecated Please use the camelCase version. */
  sign_legal_document?: Maybe<SignLegalDocumentPayload>;
  transmitServiceTicketTransmission?: Maybe<TransmitServiceTicketTransmissionPayload>;
  triggerAppointment?: Maybe<TriggerAppointmentPayload>;
  triggerTask?: Maybe<TriggerTaskPayload>;
  updateAddress?: Maybe<UpdateAddressPayload>;
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  updateAppointmentInventory?: Maybe<UpdateAppointmentInventoryPayload>;
  updateContact?: Maybe<UpdateContactPayload>;
  updateImproperlyClosedServiceTicketOrder?: Maybe<UpdateImproperlyClosedServiceTicketOrderPayload>;
  updateInsurance?: Maybe<UpdateInsurancePayload>;
  updateInternalUserPreferences?: Maybe<UpdateInternalUserPreferencesPayload>;
  updateLabOrder?: Maybe<UpdateLabOrderPayload>;
  /**
   * Can update and create any field that has accepts_nested_attributes_for in the
   * model. For deleting, the additional allow_destroy: true is required
   */
  updatePatient?: Maybe<UpdatePatientPayload>;
  updatePendingDirectSignupAccount?: Maybe<UpdatePendingDirectSignupAccountPayload>;
  updateProcedureInteraction?: Maybe<UpdateProcedureInteractionPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updateProgramCarePlan?: Maybe<UpdateProgramCarePlanPayload>;
  updateProgramEnrollment?: Maybe<UpdateProgramEnrollmentPayload>;
  updateProgramEnrollmentCareTeam?: Maybe<UpdateProgramEnrollmentCareTeamPayload>;
  updateProgramForAppointment?: Maybe<UpdateProgramForAppointmentPayload>;
  updateProgramVisit?: Maybe<UpdateProgramVisitPayload>;
  updateServiceTicket?: Maybe<UpdateServiceTicketPayload>;
  updateServiceTicketItem?: Maybe<UpdateServiceTicketItemPayload>;
  updateStripePaymentMethodNickname?: Maybe<UpdateStripePaymentMethodNicknamePayload>;
  updateTask?: Maybe<UpdateTaskPayload>;
  updateVoluntaryAlignmentOffer?: Maybe<UpdateVoluntaryAlignmentOfferPayload>;
  upsertMlFeedbackTodoMessageReassignment?: Maybe<UpsertMlFeedbackTodoMessageReassignmentPayload>;
};


export type MutationApplyTemplateAppointmentInventoryArgs = {
  input: ApplyTemplateAppointmentInventoryInput;
};


export type MutationArchiveInsuranceArgs = {
  input: ArchiveInsuranceInput;
};


export type MutationAttachDocumentToProcedureArgs = {
  input: AttachDocumentToProcedureInput;
};


export type MutationAttachServiceTicketImproperlyClosedOrderArgs = {
  input: AttachServiceTicketImproperlyClosedOrderInput;
};


export type MutationAuthorizeConnectedDevicesArgs = {
  input: AuthorizeConnectedDevicesInput;
};


export type MutationCancelServiceTicketTransmissionArgs = {
  input: CancelServiceTicketTransmissionInput;
};


export type MutationChargePatientForServiceTicketArgs = {
  input: ChargePatientForServiceTicketInput;
};


export type MutationCompleteOrdersAndSignNoteForAppointmentArgs = {
  input: CompleteOrdersAndSignNoteForAppointmentInput;
};


export type MutationCompleteProcedureInteractionArgs = {
  input: CompleteProcedureInteractionInput;
};


export type MutationCreateAppointmentInventoryArgs = {
  input: CreateAppointmentInventoryInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateHelpRequestArgs = {
  input: CreateHelpRequestInput;
};


export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};


export type MutationCreateMassAppointmentCancellationRequestArgs = {
  input: CreateMassAppointmentCancellationRequestInput;
};


export type MutationCreateMiscClericalNoteArgs = {
  input: CreateMiscClericalNoteInput;
};


export type MutationCreateMlFeedbackProcedureSuggestionArgs = {
  input: CreateMlFeedbackProcedureSuggestionInput;
};


export type MutationCreateOrUpdateMeasurementForProcedureArgs = {
  input: CreateOrUpdateMeasurementForProcedureInput;
};


export type MutationCreatePrescriptionChangeRequestsArgs = {
  input: CreatePrescriptionChangeRequestsInput;
};


export type MutationCreatePrescriptionRenewalRequestsArgs = {
  input: CreatePrescriptionRenewalRequestsInput;
};


export type MutationCreateProcedureInteractionArgs = {
  input: CreateProcedureInteractionInput;
};


export type MutationCreateProcedureInteractionIndicationArgs = {
  input: CreateProcedureInteractionIndicationInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};


export type MutationCreateVideoCallArgs = {
  input: CreateVideoCallInput;
};


export type MutationCreateVoluntaryAlignmentOfferArgs = {
  input: CreateVoluntaryAlignmentOfferInput;
};


export type MutationCreate_Prescription_Renewal_RequestsArgs = {
  input: CreatePrescriptionRenewalRequestsInput;
};


export type MutationCreate_TokenArgs = {
  input: CreateTokenInput;
};


export type MutationDebugServiceTicketTransmissionArgs = {
  input: DebugServiceTicketTransmissionInput;
};


export type MutationDeleteDocumentFromProcedureArgs = {
  input: DeleteDocumentFromProcedureInput;
};


export type MutationDeleteProcedureInteractionArgs = {
  input: DeleteProcedureInteractionInput;
};


export type MutationDeleteProcedureInteractionIndicationArgs = {
  input: DeleteProcedureInteractionIndicationInput;
};


export type MutationDeleteServiceTicketItemArgs = {
  input: DeleteServiceTicketItemInput;
};


export type MutationDetachStripePaymentMethodArgs = {
  input: DetachStripePaymentMethodInput;
};


export type MutationDismissMlBillingProcedureSuggestionArgs = {
  input: DismissMlBillingProcedureSuggestionInput;
};


export type MutationEndVideoCallArgs = {
  input: EndVideoCallInput;
};


export type MutationExportOrphanedOrdersArgs = {
  input: ExportOrphanedOrdersInput;
};


export type MutationRemoveAppointmentInventoriesArgs = {
  input: RemoveAppointmentInventoriesInput;
};


export type MutationRemoveAppointmentInventoryArgs = {
  input: RemoveAppointmentInventoryInput;
};


export type MutationResendDirectSignupEmailArgs = {
  input: ResendDirectSignupEmailInput;
};


export type MutationSignLegalDocumentArgs = {
  input: SignLegalDocumentInput;
};


export type MutationSign_Legal_DocumentArgs = {
  input: SignLegalDocumentInput;
};


export type MutationTransmitServiceTicketTransmissionArgs = {
  input: TransmitServiceTicketTransmissionInput;
};


export type MutationTriggerAppointmentArgs = {
  input: TriggerAppointmentInput;
};


export type MutationTriggerTaskArgs = {
  input: TriggerTaskInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


export type MutationUpdateAppointmentInventoryArgs = {
  input: UpdateAppointmentInventoryInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateImproperlyClosedServiceTicketOrderArgs = {
  input: UpdateImproperlyClosedServiceTicketOrderInput;
};


export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};


export type MutationUpdateInternalUserPreferencesArgs = {
  input: UpdateInternalUserPreferencesInput;
};


export type MutationUpdateLabOrderArgs = {
  input: UpdateLabOrderInput;
};


export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};


export type MutationUpdatePendingDirectSignupAccountArgs = {
  input: UpdatePendingDirectSignupAccountInput;
};


export type MutationUpdateProcedureInteractionArgs = {
  input: UpdateProcedureInteractionInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateProgramCarePlanArgs = {
  input: UpdateProgramCarePlanInput;
};


export type MutationUpdateProgramEnrollmentArgs = {
  input: UpdateProgramEnrollmentInput;
};


export type MutationUpdateProgramEnrollmentCareTeamArgs = {
  input: UpdateProgramEnrollmentCareTeamInput;
};


export type MutationUpdateProgramForAppointmentArgs = {
  input: UpdateProgramForAppointmentInput;
};


export type MutationUpdateProgramVisitArgs = {
  input: UpdateProgramVisitInput;
};


export type MutationUpdateServiceTicketArgs = {
  input: UpdateServiceTicketInput;
};


export type MutationUpdateServiceTicketItemArgs = {
  input: UpdateServiceTicketItemInput;
};


export type MutationUpdateStripePaymentMethodNicknameArgs = {
  input: UpdateStripePaymentMethodNicknameInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateVoluntaryAlignmentOfferArgs = {
  input: UpdateVoluntaryAlignmentOfferInput;
};


export type MutationUpsertMlFeedbackTodoMessageReassignmentArgs = {
  input: UpsertMlFeedbackTodoMessageReassignmentInput;
};

export type Note = {
  appointmentId?: Maybe<Scalars['Int']>;
  emailSubject?: Maybe<Scalars['String']>;
  encounterId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  internalUserId?: Maybe<Scalars['Int']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  noteBodyText?: Maybe<Scalars['String']>;
  noteTypeId?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  signedById?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
};

export type NoteTemplate = {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalUserId?: Maybe<Scalars['Int']>;
  messageTemplateId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Observation = {
  components: Array<ObservationComponent>;
  effectiveDateTime?: Maybe<Scalars['DateTime']>;
  fromDevice: Scalars['Boolean'];
  id: Scalars['ID'];
  integerValue?: Maybe<Scalars['Int']>;
  performerName?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type ObservationComponent = {
  code: Scalars['String'];
  integerValue?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum OccurrenceOptions {
  Single = 'single',
  Multiple = 'multiple'
}

export type Office = {
  address?: Maybe<Address>;
  appointmentInventories?: Maybe<AppointmentInventoryConnection>;
  b2bCompanyId?: Maybe<Scalars['ID']>;
  /** @deprecated Please use the camelCase version. */
  b2b_company_id?: Maybe<Scalars['ID']>;
  /** @deprecated Please use the camelCase version. */
  closed?: Maybe<Scalars['Boolean']>;
  closureReason?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  closure_reason?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  emailAccount?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  email_account?: Maybe<Scalars['String']>;
  hours: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  image_url?: Maybe<Scalars['String']>;
  isHidden: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_hidden: Scalars['Boolean'];
  labHours?: Maybe<Scalars['String']>;
  labInventory?: Maybe<LabInventory>;
  /** @deprecated Please use the camelCase version. */
  lab_hours?: Maybe<Scalars['String']>;
  mapLink?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  map_link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  officeGroups?: Maybe<Array<Scalars['JSON']>>;
  pediatricServices: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  pediatric_services: Scalars['Boolean'];
  permalink?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  serviceArea?: Maybe<ServiceArea>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  structuredHours?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  virtual: Scalars['Boolean'];
};


export type OfficeAppointmentInventoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type OfficeImageUrlArgs = {
  size?: Maybe<Scalars['String']>;
};


export type OfficeImage_UrlArgs = {
  size?: Maybe<Scalars['String']>;
};

export type OpenPracticeMessage = {
  createdAt: Scalars['DateTime'];
  hl7Msg?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  operation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum OpmEligibilityStatus {
  Pending = 'pending',
  SelfPay = 'self_pay',
  Error = 'error',
  Inactive = 'inactive',
  Active = 'active'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum ParamOptions {
  Amount = 'amount'
}

export type Patient = {
  activeInsurances: Array<Insurance>;
  /** @deprecated Please use the camelCase version. */
  active_insurances: Array<Insurance>;
  adminComments?: Maybe<Scalars['String']>;
  /** If the patient has not visited their home office in the last 18 months, suggest the most visited office during that period */
  alternateHomeOfficeSuggestion?: Maybe<Office>;
  birthdayAlert?: Maybe<Scalars['String']>;
  /** A list of appointment types bookable by the patient */
  bookableAppointmentTypes?: Maybe<Array<AppointmentType>>;
  careTeam: CareTeam;
  /** @deprecated Please use the camelCase version. */
  care_team: CareTeam;
  connectedDevicesAuthorized: Scalars['Boolean'];
  email: Scalars['String'];
  /** A list of the patient's emails */
  emails?: Maybe<Array<Email>>;
  /** The patient's past, canceled, and no show appointments */
  endedAndNotUtilizedAppointments: AppointmentConnection;
  fitbitAuthorized: Scalars['Boolean'];
  healthkitAuthorized: Scalars['Boolean'];
  homeOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  home_office?: Maybe<Office>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isPediatric: Scalars['Boolean'];
  isVirtual?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  is_active: Scalars['Boolean'];
  /**
   * The licensing body that this patient belongs to. This is effectively a
   * StateType, though non-states like DC and Puerto Rico are included.
   * If null, it means the patient is part of the virtual service area, and has no usable address.
   *
   * If given a latitude and longitude, the licensing body is looked up based on
   * that location. If reverse-geocoding fails, then we fall back on the above behavior.
   */
  licensingBody?: Maybe<State>;
  memberSinceAt: Scalars['DateTime'];
  /** @deprecated Please use the camelCase version. */
  member_since_at: Scalars['DateTime'];
  mostRecentFitbitMeasurement?: Maybe<Measurement>;
  mostRecentHealthkitMeasurement?: Maybe<Measurement>;
  /** The patient's past and current appointments */
  pastAndCurrentAppointments: AppointmentConnection;
  /**
   * The patient's past and current appointments
   * @deprecated The name and description do not match. Please use past_and_current_appointments
   */
  pastAppointments: AppointmentConnection;
  /**
   * The patient's past and current appointments
   * @deprecated The name and description do not match. Please use past_and_current_appointments
   */
  past_appointments: AppointmentConnection;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  /** @deprecated Use `care_team.pcp` instead. */
  pcp?: Maybe<Provider>;
  posts: TimelinePostConnection;
  preferences?: Maybe<PatientPreferences>;
  /** patient's preferred email. In a few cases this can be null */
  preferredEmail?: Maybe<Email>;
  /**
   * patient's preferred email. In a few cases this can be null
   * @deprecated Please use the camelCase version.
   */
  preferred_email?: Maybe<Email>;
  profileInfo: PatientProfileInfo;
  /** @deprecated Please use the camelCase version. */
  profile_info: PatientProfileInfo;
  /** Returns a list of programs available to a patient based on their available appointment types and problem type. */
  programSuggestions: ProgramSuggestionConnection;
  serviceArea: ServiceArea;
  /** @deprecated Please use the camelCase version. */
  service_area: ServiceArea;
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** The patient's timezone */
  timezoneTzinfo: Scalars['TZInfo'];
  /** A list of the patient's upcoming and current appointments */
  upcomingAndCurrentAppointments: Array<Appointment>;
  /** A list of the patient's upcoming appointments */
  upcomingAppointments: Array<Appointment>;
  /** A list of the patient's upcoming appointments by appointment type */
  upcomingAppointmentsByType: AppointmentConnection;
  /** A list of the patient's upcoming appointments that are suitable for a discussion of connected device data */
  upcomingAppointmentsForConnectedDeviceDiscussion: AppointmentConnection;
  /**
   * A list of the patient's upcoming appointments
   * @deprecated Please use the camelCase version.
   */
  upcoming_appointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments by appointment type
   * @deprecated Please use the camelCase version.
   */
  upcoming_appointments_by_type: AppointmentConnection;
};


export type PatientBookableAppointmentTypesArgs = {
  appointmentCategory?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['ID']>;
  serviceAreaId?: Maybe<Scalars['ID']>;
  topicName?: Maybe<Scalars['String']>;
};


export type PatientEndedAndNotUtilizedAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PatientLicensingBodyArgs = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type PatientPastAndCurrentAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PatientPastAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PatientPast_AppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PatientPostsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type PatientProgramSuggestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  problemTypeId: Scalars['ID'];
};


export type PatientUpcomingAppointmentsByTypeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  appointmentTypeIds: Array<Scalars['ID']>;
};


export type PatientUpcomingAppointmentsForConnectedDeviceDiscussionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PatientUpcoming_Appointments_By_TypeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  appointmentTypeIds: Array<Scalars['ID']>;
};

export type PatientCharge = {
  amountOwedCents: Scalars['Int'];
  amountPaidCents: Scalars['Int'];
  chargeAmount: Scalars['Float'];
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type PatientChargePayment = {
  amountPaidTowards: Scalars['String'];
  id: Scalars['ID'];
  paymentType: Scalars['String'];
};

export enum PatientPhoneNumberKind {
  Home = 'home',
  Mobile = 'mobile',
  Work = 'work',
  Other = 'other'
}

export type PatientPreferences = {
  acceptsClinicalEmails: Scalars['Boolean'];
  acceptsOnlineBills: Scalars['Boolean'];
  acceptsPatientTaskEmails?: Maybe<Scalars['Boolean']>;
  acceptsPatientTaskPush?: Maybe<Scalars['Boolean']>;
  avsHideWeight: Scalars['Boolean'];
  chartHidden: Scalars['Boolean'];
  connectedDevicesOptIn?: Maybe<Scalars['Boolean']>;
  fullTextNotifications?: Maybe<Scalars['Boolean']>;
  healthInformationExchangeLegalTextVersion?: Maybe<Scalars['Int']>;
  healthInformationExchangeOptIn?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  marketingEmailOptOut?: Maybe<Scalars['Boolean']>;
};

export type PatientProfileInfo = {
  addresses: Array<Address>;
  ageInYears?: Maybe<Scalars['Int']>;
  allPhoneNumberKinds: Array<PatientPhoneNumberKind>;
  allPronounGroups: Array<Scalars['String']>;
  allRelationshipTypes: Array<Scalars['String']>;
  allSexes: Array<Scalars['String']>;
  avatarFileName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  avatar_file_name?: Maybe<Scalars['String']>;
  /**
   * @deprecated the word 'display' is redundant. Use pronounGroup instead. Any other representations of the
   *           pronouns are only a backend concern.
   */
  displayPronouns?: Maybe<Scalars['String']>;
  /**
   * @deprecated the word 'display' is redundant. Use pronounGroup instead. Any other representations of the
   *           pronouns are only a backend concern.
   */
  display_pronouns?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  employerZipCode?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  first_name: Scalars['String'];
  genderDetails?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  gender_details?: Maybe<Scalars['String']>;
  insuranceVerifiedWithinPastYear: Scalars['Boolean'];
  insurancesManuallyVerifiedAt?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  last_name: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  middle_name?: Maybe<Scalars['String']>;
  onlyHasSelfPay: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  phone_number?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  preferred_name?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  /** @deprecated Please use the camelCase version. */
  primary_address?: Maybe<Address>;
  profileImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  profile_image_url?: Maybe<Scalars['String']>;
  pronounGroup?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  billingName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  cardNickname?: Maybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export type Pharmacy = {
  address?: Maybe<Address>;
  displayAddressStreet?: Maybe<Scalars['String']>;
  /** Pharmacy brand name if it exists; otherwise, the pharmacy name title-ized */
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isMailOrder: Scalars['Boolean'];
  name: Scalars['String'];
  workPhoneNumber?: Maybe<Scalars['String']>;
};

/** The connection type for Pharmacy. */
export type PharmacyConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PharmacyEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Pharmacy>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PharmacyEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Pharmacy>;
};

export type PhoneNumber = {
  ext?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: PhoneNumberKind;
  number: Scalars['String'];
};

export type PhoneNumberAttributes = {
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<PhoneNumberKind>;
  number?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  _destroy?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export enum PhoneNumberKind {
  Work = 'work',
  Fax = 'fax',
  Mobile = 'mobile',
  PatientAssistance = 'patient_assistance',
  BackOffice = 'back_office',
  Pager = 'pager',
  Home = 'home',
  Other = 'other'
}

/** Interface for different plans */
export type Plan = {
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type PrescribingCredential = {
  serviceArea?: Maybe<ServiceArea>;
};

export enum Priority {
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

export type ProblemCode = {
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type ProblemCodeExtension = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type ProblemCodeLocation = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProblemHistory = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  problemCodeLocation?: Maybe<ProblemCodeLocation>;
  problemId: Scalars['Int'];
  problemType?: Maybe<ProblemType>;
};

export type ProblemType = {
  clinicalDescription: Scalars['String'];
  commonProcedureTypes?: Maybe<Array<ProcedureType>>;
  id: Scalars['ID'];
};

export type ProcedureCode = {
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

/** Type for procedure form type object */
export type ProcedureFormType = {
  id: Scalars['ID'];
  requiredOccurrences?: Maybe<OccurrenceOptions>;
  requiredParams?: Maybe<Array<ParamOptions>>;
  systemName?: Maybe<Scalars['String']>;
};

/** Type for procedure interaction object */
export type ProcedureInteraction = {
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<InternalUser>;
  createdAt: Scalars['DateTime'];
  dynamicForm?: Maybe<Scalars['JSON']>;
  formData?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  indications: Array<Maybe<Indication>>;
  measurements?: Maybe<Array<Maybe<Measurement>>>;
  patient: Patient;
  procedureSummary?: Maybe<Scalars['String']>;
  procedureType?: Maybe<ProcedureType>;
  resultDocuments: Array<Document>;
  resultsInterpretation?: Maybe<Scalars['String']>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  reviewedBy?: Maybe<InternalUser>;
  state: Scalars['String'];
  totalComments?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ProcedureMeasurementInputObject = {
  /**
   * The result value of the test. Can be string or number. For example 'non
   * reactive' or 78. Null values will soft delete the measurement.
   */
  value?: Maybe<Scalars['String']>;
  /** The id of the measurement type associated with this measurement. */
  typeId: Scalars['ID'];
};

/** Type for procedure order object */
export type ProcedureOrder = {
  id: Scalars['ID'];
  measurements?: Maybe<Array<Maybe<Measurement>>>;
};

/** Source for a procedure */
export type ProcedureOrigin = ProcedureInteraction | ProcedureOrder;

export enum ProcedureOriginType {
  ProcedureInteraction = 'ProcedureInteraction',
  ProcedureOrder = 'ProcedureOrder'
}

export type ProcedureType = {
  billableUnitOfMeasure?: Maybe<MedicationUnitOfMeasure>;
  billableUnitOfMeasureId?: Maybe<Scalars['Int']>;
  billingUnitOfMeasureHeading?: Maybe<Scalars['String']>;
  clinicalDescription: Scalars['String'];
  clinicalGuidance?: Maybe<Scalars['String']>;
  diagnosticGroup?: Maybe<DiagnosticGroup>;
  externalOnly?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isBillableAmountRequired: Scalars['Boolean'];
  isDocumentReferenceRequired: Scalars['Boolean'];
  isKidneyFunctionDocRequired: Scalars['Boolean'];
  isLateralityRequired: Scalars['Boolean'];
  isPregnancyDocRequired: Scalars['Boolean'];
  isResultsInterpretationRequired: Scalars['Boolean'];
  layDescription: Scalars['String'];
  maxBillableAmount?: Maybe<Scalars['Float']>;
  measurementTypes: Array<MeasurementType>;
  procedureCodes?: Maybe<Array<Maybe<ProcedureCode>>>;
  procedureFormType?: Maybe<ProcedureFormType>;
};

export type Program = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
  programEnrollments?: Maybe<ProgramEnrollmentConnection>;
};


export type ProgramProgramEnrollmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProgramCarePlan = {
  carePlan?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  programEnrollment: ProgramEnrollment;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<InternalUser>;
};

export type ProgramEnrollment = {
  careTeam: ProgramEnrollmentCareTeam;
  frequency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  outreachComment?: Maybe<Scalars['String']>;
  preferredTime?: Maybe<Scalars['String']>;
  program: Program;
  programCarePlan?: Maybe<ProgramCarePlan>;
  programPhase?: Maybe<ProgramPhase>;
};

export type ProgramEnrollmentCareTeam = {
  id: Scalars['ID'];
  patient: Patient;
  providers: Array<Provider>;
  status: Scalars['String'];
};

/** The connection type for ProgramEnrollment. */
export type ProgramEnrollmentConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProgramEnrollmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProgramEnrollment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProgramEnrollmentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramEnrollment>;
};

export type ProgramPhase = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProgramSuggestion = {
  description: Scalars['String'];
  displayName: Scalars['String'];
  externalUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalName: Scalars['String'];
  messageTemplate?: Maybe<MessageTemplate>;
};


export type ProgramSuggestionMessageTemplateArgs = {
  patientId?: Maybe<Scalars['ID']>;
};

/** The connection type for ProgramSuggestion. */
export type ProgramSuggestionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProgramSuggestionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProgramSuggestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProgramSuggestionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramSuggestion>;
};

export type ProgramVisit = {
  formData?: Maybe<ProgramVisitFormData>;
  id: Scalars['ID'];
  summary?: Maybe<Summary>;
  summaryOfVisit?: Maybe<Scalars['String']>;
};

export type ProgramVisitFormData = {
  time?: Maybe<Scalars['Int']>;
};

export type Provider = {
  acceptingNewPatients?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  accepting_new_patients?: Maybe<Scalars['Boolean']>;
  appointmentInventories?: Maybe<AppointmentInventoryConnection>;
  bio?: Maybe<Scalars['String']>;
  bookable: Scalars['Boolean'];
  defaultOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  displayName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  display_name: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  first_name: Scalars['String'];
  fullImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  full_image_url?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isPatientBookable: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_active: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_patient_bookable: Scalars['Boolean'];
  lastName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  last_name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  offices: Array<Office>;
  outOfOffice?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  out_of_office?: Maybe<Scalars['Boolean']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  profile_image_url?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use the camelCase version. */
  return_date?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  school?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<ServiceArea>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  specialtyTypes?: Maybe<Array<Maybe<SpecialtyType>>>;
  /** @deprecated Please use the camelCase version. */
  specialty_types?: Maybe<Array<Maybe<SpecialtyType>>>;
  suffix?: Maybe<Scalars['String']>;
  worksWith?: Maybe<Array<Maybe<Provider>>>;
  /** @deprecated Please use the camelCase version. */
  works_with?: Maybe<Array<Maybe<Provider>>>;
};


export type ProviderAppointmentInventoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

/** The connection type for Provider. */
export type ProviderConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProviderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Provider>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProviderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Provider>;
};

export type Query = {
  /** Fetch information about anonymous flags for the current session */
  anonymousFlagData: AnonymousFlagData;
  appointment?: Maybe<Appointment>;
  /** @deprecated The original implementation of this field would, on Production+Staging, always return an empty array. Also the name is singular even though it's returning an array of elements. Please use appointmentCancellationReasons instead. */
  appointmentCancellationReason?: Maybe<Array<AppointmentCancellationReason>>;
  appointmentCancellationReasons: Array<AppointmentCancellationReason>;
  appointmentInventory?: Maybe<AppointmentInventory>;
  /** Valid reasons an appointment slot can be marked as blocked */
  appointmentInventoryBlocks: Array<AppointmentInventoryBlock>;
  appointmentLengths: Array<AppointmentLength>;
  appointmentTypes?: Maybe<Array<AppointmentType>>;
  b2bCompany?: Maybe<B2bCompany>;
  checkVersion: CheckVersion;
  contact?: Maybe<Contact>;
  contacts: Array<Maybe<Contact>>;
  contactsStatuses: Array<Maybe<ContactsStatus>>;
  /** @deprecated Please use the camelCase version. */
  contacts_statuses: Array<Maybe<ContactsStatus>>;
  diagnosticGroups: Array<Maybe<DiagnosticGroup>>;
  directSignupToken?: Maybe<DirectSignupToken>;
  elasticsearchCredentials?: Maybe<Credentials>;
  /** @deprecated Please use the camelCase version. */
  elasticsearch_credentials?: Maybe<Credentials>;
  encounter?: Maybe<Encounter>;
  /** A feature flag defined in the Rollout object */
  featureFlag: FeatureFlag;
  featureFlags: Array<Maybe<Scalars['String']>>;
  /** @deprecated Please use the camelCase version. */
  feature_flags: Array<Maybe<Scalars['String']>>;
  firebase?: Maybe<Firebase>;
  hearabouts: Array<Maybe<Hearabout>>;
  helpflowTypes: Array<HelpFlow>;
  indication?: Maybe<Indication>;
  institutions: Array<Maybe<Institution>>;
  insurance?: Maybe<Insurance>;
  insuranceNetworks: Array<Maybe<InsuranceNetwork>>;
  insuranceRelationshipTypes: Array<Maybe<InsuranceRelationshipType>>;
  insuranceTypes: Array<Maybe<InsuranceType>>;
  /** @deprecated Please use the camelCase version. */
  insurance_networks: Array<Maybe<InsuranceNetwork>>;
  /** @deprecated Please use the camelCase version. */
  insurance_relationship_types: Array<Maybe<InsuranceRelationshipType>>;
  /** @deprecated Please use the camelCase version. */
  insurance_types: Array<Maybe<InsuranceType>>;
  internalUser: InternalUser;
  internalUsers: InternalUserConnection;
  legacyFirebase?: Maybe<Firebase>;
  /** The required legal documents */
  legalDocuments: Array<Maybe<LegalDocument>>;
  /** The required legal documents for beneficiary */
  legalDocumentsForBeneficiary: Array<LegalDocument>;
  /**
   * The required legal documents
   * @deprecated Please use the camelCase version.
   */
  legal_documents: Array<Maybe<LegalDocument>>;
  /**
   * A list of bodies (e.g. states and non-states like Washington DC) that are
   * relevant with respect to medical licensing. Differs from the `states` field
   * only in name; since `states` actually includes regions that aren't states, it
   * seems clearer to use a more generic name.
   */
  licensingBodies: Array<State>;
  massAppointmentChangeRequest?: Maybe<MassAppointmentChangeRequest>;
  massAppointmentChangeRequests: MassAppointmentChangeRequestConnection;
  measurementOrderTypeSources: Array<MeasurementOrderTypeSource>;
  measurementTypes: MeasurementTypeConnection;
  measurementsValidationRules: MeasurementsValidationRules;
  mlBillingProcedureSuggestion?: Maybe<MlBillingProcedureSuggestion>;
  mlFeedbackProcedureSuggestion?: Maybe<MlFeedbackProcedureSuggestion>;
  note?: Maybe<Note>;
  noteTemplate?: Maybe<NoteTemplate>;
  observations?: Maybe<Array<Observation>>;
  offices: Array<Maybe<Office>>;
  /** Returns a list of offices that have open lab hours for the current week */
  officesWithOpenLabHoursInServiceArea?: Maybe<Array<Office>>;
  openPracticeMessage?: Maybe<OpenPracticeMessage>;
  openTokToken: Scalars['String'];
  patient?: Maybe<Patient>;
  patientCharge?: Maybe<PatientCharge>;
  patientChargePayment?: Maybe<PatientChargePayment>;
  patients: Array<Patient>;
  /**
   * Searches for pharmacies close to a location OR mail order pharmacies.
   *
   * When not searching for mail order pharmacies, a location or patient must be provided.
   *
   * If no location is provided, the location of the patient's home address, home
   * office address, service area default office address will be used in that order.
   */
  pharmacySearch: PharmacyConnection;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  procedureOrder?: Maybe<ProcedureOrder>;
  procedureType?: Maybe<ProcedureType>;
  profile?: Maybe<InternalUser>;
  program: Program;
  programVisit?: Maybe<ProgramVisit>;
  /** @deprecated Use providersConnection instead; this deprecated field should have ids as an array of strings instead of an array of integers, and should be paginated. */
  providers: Array<Maybe<Provider>>;
  providersConnection: ProviderConnection;
  /** @deprecated Channel routing is being deprecated in favor of step-based routing */
  routingMetadata?: Maybe<RoutingMetadata>;
  scheduleTemplateVariants: Array<ScheduleTemplateVariant>;
  serviceArea: ServiceArea;
  serviceAreas: Array<Maybe<ServiceArea>>;
  serviceTicket?: Maybe<ServiceTicket>;
  serviceTicketItem?: Maybe<ServiceTicketItem>;
  serviceTicketPaymentTransmission?: Maybe<ServiceTicketPaymentTransmission>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  /** @deprecated Please use the camelCase version. */
  service_areas: Array<Maybe<ServiceArea>>;
  signedS3Url?: Maybe<SignedS3Url>;
  specialties: Array<Maybe<Specialty>>;
  states: Array<Maybe<State>>;
  stripeSetupIntent?: Maybe<StripeSetupIntent>;
  summary?: Maybe<Summary>;
  task?: Maybe<Task>;
  textTemplate?: Maybe<TextTemplate>;
  topics: Array<Topic>;
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
  voluntaryAlignmentOffers: Array<VoluntaryAlignmentOffer>;
  whitelistedEmployee?: Maybe<WhitelistedEmployee>;
  whitelistedEmployeeDependent?: Maybe<WhitelistedEmployee>;
  zoom: Zoom;
};


export type QueryAnonymousFlagDataArgs = {
  clientToken?: Maybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentInventoryArgs = {
  id: Scalars['ID'];
};


export type QueryB2bCompanyArgs = {
  reCaptchaToken?: Maybe<Scalars['String']>;
  b2bEmail?: Maybe<Scalars['String']>;
};


export type QueryCheckVersionArgs = {
  appId: Scalars['String'];
  currentVersion: Scalars['String'];
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryDirectSignupTokenArgs = {
  token: Scalars['String'];
};


export type QueryEncounterArgs = {
  id: Scalars['ID'];
};


export type QueryFeatureFlagArgs = {
  name: Scalars['String'];
};


export type QueryIndicationArgs = {
  id: Scalars['ID'];
};


export type QueryInsuranceArgs = {
  id: Scalars['ID'];
};


export type QueryInternalUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withMassCancellations?: Maybe<Scalars['Boolean']>;
};


export type QueryLegalDocumentsForBeneficiaryArgs = {
  beneficiaryId: Scalars['ID'];
};


export type QueryMassAppointmentChangeRequestArgs = {
  id: Scalars['ID'];
};


export type QueryMassAppointmentChangeRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  changeTypes: Array<MassAppointmentChangeRequestChangeType>;
  submittedByIds?: Maybe<Array<Scalars['ID']>>;
  date?: Maybe<Scalars['Date']>;
  officeIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryMeasurementOrderTypeSourcesArgs = {
  limitByCurrentUser?: Maybe<Scalars['Boolean']>;
};


export type QueryMeasurementTypesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  forConnectedDeviceOrders?: Maybe<Scalars['Boolean']>;
  forInsightsPanel?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['ID']>;
};


export type QueryMlBillingProcedureSuggestionArgs = {
  id: Scalars['ID'];
};


export type QueryMlFeedbackProcedureSuggestionArgs = {
  id: Scalars['ID'];
};


export type QueryNoteArgs = {
  id: Scalars['ID'];
};


export type QueryNoteTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryObservationsArgs = {
  patientId: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
};


export type QueryOfficesArgs = {
  ids?: Maybe<Array<Scalars['Int']>>;
  excludeVirtual?: Maybe<Scalars['Boolean']>;
};


export type QueryOfficesWithOpenLabHoursInServiceAreaArgs = {
  id: Scalars['ID'];
};


export type QueryOpenPracticeMessageArgs = {
  id: Scalars['ID'];
};


export type QueryOpenTokTokenArgs = {
  sessionId: Scalars['String'];
};


export type QueryPatientArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPatientChargeArgs = {
  id: Scalars['ID'];
};


export type QueryPatientChargePaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPatientsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPharmacySearchArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  mailOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryProcedureInteractionArgs = {
  id: Scalars['ID'];
};


export type QueryProcedureOrderArgs = {
  id: Scalars['ID'];
};


export type QueryProcedureTypeArgs = {
  id: Scalars['ID'];
};


export type QueryProgramArgs = {
  id: Scalars['ID'];
};


export type QueryProgramVisitArgs = {
  id?: Maybe<Scalars['ID']>;
  summaryId?: Maybe<Scalars['ID']>;
};


export type QueryProvidersArgs = {
  ids?: Maybe<Array<Scalars['Int']>>;
};


export type QueryProvidersConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  roleIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryRoutingMetadataArgs = {
  uuid: Scalars['ID'];
};


export type QueryServiceAreaArgs = {
  id: Scalars['Int'];
};


export type QueryServiceTicketArgs = {
  id: Scalars['ID'];
};


export type QueryServiceTicketItemArgs = {
  id: Scalars['ID'];
};


export type QueryServiceTicketPaymentTransmissionArgs = {
  id: Scalars['ID'];
};


export type QueryServiceTicketTransmissionArgs = {
  id: Scalars['ID'];
};


export type QuerySignedS3UrlArgs = {
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};


export type QueryStripeSetupIntentArgs = {
  id: Scalars['String'];
};


export type QuerySummaryArgs = {
  id: Scalars['ID'];
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTextTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryVoluntaryAlignmentOfferArgs = {
  id: Scalars['ID'];
};


export type QueryVoluntaryAlignmentOffersArgs = {
  patientId: Scalars['ID'];
};


export type QueryWhitelistedEmployeeArgs = {
  reCaptchaToken?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  b2bCompanyId?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
};


export type QueryWhitelistedEmployeeDependentArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  parentId: Scalars['String'];
  b2bCompanyId: Scalars['String'];
  reCaptchaToken?: Maybe<Scalars['String']>;
};

export enum Rating {
  Great = 'great',
  Acceptable = 'acceptable',
  Problematic = 'problematic',
  Unrated = 'unrated'
}

export type Remindable = {
  /** id of remindable object */
  id: Scalars['ID'];
  /**
   * class name of remindable object
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String'];
  /** string to indicate a status of the remindable object */
  notifiableStatus?: Maybe<Scalars['String']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']>;
  /** notification subtitle based on remindable type */
  notificationSubtitle?: Maybe<Scalars['String']>;
  /** notification title based on remindable type */
  notificationTitle: Scalars['String'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String'];
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']>;
  /** a weight given to remindable objects to define sort order */
  sortWeight: Scalars['Int'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int'];
  /** universal link for notification */
  universalLink: Scalars['String'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String'];
};

/** Autogenerated input type of RemoveAppointmentInventories */
export type RemoveAppointmentInventoriesInput = {
  date: Scalars['DateTime'];
  providerId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveAppointmentInventories */
export type RemoveAppointmentInventoriesPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  inventoryIds?: Maybe<Array<Scalars['ID']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of RemoveAppointmentInventory */
export type RemoveAppointmentInventoryInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveAppointmentInventory */
export type RemoveAppointmentInventoryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ResendDirectSignupEmail */
export type ResendDirectSignupEmailInput = {
  token: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ResendDirectSignupEmail */
export type ResendDirectSignupEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type RoleType = Assignee & {
  assignable: Scalars['Boolean'];
  displayName: Scalars['String'];
  /** @deprecated IDs should be of type ID, not Integer */
  id: Scalars['Int'];
  name: Scalars['String'];
  /**
   * Once clients switch over to :normalized_id, we can change the deprecated :id
   * field to be of type ID and then switch clients back.
   */
  normalizedId: Scalars['ID'];
  taskFilters: Array<Maybe<TaskFilter>>;
  /** @deprecated Please use the camelCase version. */
  task_filters: Array<Maybe<TaskFilter>>;
};

export type RoutingMetadata = {
  channelRecommended?: Maybe<Scalars['String']>;
  channelSelected?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  encounter?: Maybe<Encounter>;
  flowType: Scalars['String'];
  patient?: Maybe<Patient>;
  patientRouted: Scalars['Boolean'];
  reasonText?: Maybe<Scalars['String']>;
  recommendationAccepted: Scalars['Boolean'];
  topic?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type S3Attributes = {
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type ScheduleTemplate = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ScheduleTemplateVariant = {
  duration: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  scheduleTemplate?: Maybe<ScheduleTemplate>;
};

export type ScrubbedWhitelistedEmployee = {
  id: Scalars['ID'];
  registered: Scalars['Boolean'];
};

export type ServiceArea = {
  code?: Maybe<Scalars['String']>;
  defaultOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  faxNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  fax_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceCarriers: Array<Maybe<InsuranceCarrier>>;
  /** @deprecated Please use the camelCase version. */
  insurance_carriers: Array<Maybe<InsuranceCarrier>>;
  latitude?: Maybe<Scalars['Float']>;
  /**
   * The licensing body that this service area belongs to. This is effectively a
   * StateType, though non-states like DC are included.
   * If null, it means this service area is virtual so any licensing body applies.
   *
   * If you're trying to determine the licensing body for a patient, use patient ->
   * licensingBody instead. In the future, it's possible that the licensing body is
   * determined from a combination of address and service area, so try not to
   * couple your client to the service area -> licensing body approach.
   */
  licensingBody?: Maybe<State>;
  locationDescription?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  location_description?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  offices?: Maybe<Array<Maybe<Office>>>;
  registrationHidden?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  registration_hidden?: Maybe<Scalars['Boolean']>;
  serviceAreaGroup?: Maybe<ServiceAreaGroup>;
  stripePlan?: Maybe<StripePlan>;
  /** @deprecated Please use the camelCase version. */
  stripe_plan?: Maybe<StripePlan>;
  timezone?: Maybe<Scalars['TZInfo']>;
  virtual: Scalars['Boolean'];
};


export type ServiceAreaInsuranceCarriersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type ServiceAreaInsurance_CarriersArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type ServiceAreaGroup = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  serviceAreas?: Maybe<Array<Maybe<ServiceArea>>>;
  /** @deprecated Please use the camelCase version. */
  service_areas?: Maybe<Array<Maybe<ServiceArea>>>;
};

export type ServiceTicket = {
  administrativeComments?: Maybe<Scalars['String']>;
  appointment?: Maybe<Appointment>;
  id: Scalars['ID'];
  incompleteReasons: Array<Scalars['String']>;
  internalUser: InternalUser;
  labOrders?: Maybe<Array<LabOrder>>;
  processingInstructions?: Maybe<Scalars['String']>;
  serviceTicketItems: Array<ServiceTicketItem>;
  serviceTicketTransmissionId?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
  visitProcedure?: Maybe<ServiceTicketItem>;
  visitSummary?: Maybe<Summary>;
};

export type ServiceTicketImproperlyClosedOrder = {
  id: Scalars['ID'];
  possibleServiceTicketId?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
};

export type ServiceTicketItem = {
  billingAmount?: Maybe<Scalars['Float']>;
  billingAmountOwed: Scalars['Float'];
  billingMethod?: Maybe<Scalars['String']>;
  /** Informs if the item is marked as self pay */
  billingMethodIsSelfPay: Scalars['Boolean'];
  /** CPT codes for a given service ticket item */
  cpts?: Maybe<Array<Scalars['String']>>;
  /** Direct billing packages that this item is covered by */
  enterpriseQualifyingBillingPackages: Array<Scalars['String']>;
  /** Informs if the item is hidden from the note */
  hideFromNote: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Indications for a given service ticket item */
  indications?: Maybe<Array<Scalars['String']>>;
  /** Displays if a Visit Procedure has new findings */
  newFindings?: Maybe<Scalars['Boolean']>;
  patientCharge?: Maybe<PatientCharge>;
  /** Displays if an item has been marked as direct bill automatically. */
  patientDirectBillable?: Maybe<Scalars['Boolean']>;
  patientResponsibilityType?: Maybe<Scalars['String']>;
  /**
   * Informs if the item is marked as self pay
   * @deprecated Replaced by billingMethodIsSelfPay
   */
  selfPay?: Maybe<Scalars['Boolean']>;
  /** Amount in cents that should be paid if doing self-pay */
  selfPayAmount?: Maybe<Scalars['Float']>;
  selfPayReason?: Maybe<Scalars['String']>;
  /** Informs if the item is able to be marked self pay */
  selfPayable: Scalars['Boolean'];
  serviceTicketId?: Maybe<Scalars['Int']>;
  /** Shorter display title for a service ticket item */
  shortTitle?: Maybe<Scalars['String']>;
  /** Display summary for a service ticket item */
  summary?: Maybe<Scalars['String']>;
  /** Display title for a service ticket item */
  title?: Maybe<Scalars['String']>;
  /** Displays if a Visit Procedure is Virtual */
  virtual?: Maybe<Scalars['Boolean']>;
};

export type ServiceTicketPaymentTransmission = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastTransmissionAttemptAt?: Maybe<Scalars['DateTime']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  patientChargePayment: PatientChargePayment;
  state: Scalars['String'];
};

export type ServiceTicketTransmission = {
  /** Service Ticket Items Associated to  */
  closedServiceTicketItems: Array<ServiceTicketItem>;
  /** Service Tickets Associated to  */
  closedServiceTickets: Array<ServiceTicket>;
  createdAt: Scalars['DateTime'];
  /** Output for a rules engine run in debug mode */
  debugOutput?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastHl7Message?: Maybe<Scalars['String']>;
  lastTransmissionAttemptAt?: Maybe<Scalars['DateTime']>;
  markedReadyAt?: Maybe<Scalars['DateTime']>;
  mutatedData?: Maybe<Scalars['String']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  parsedErrorMessage?: Maybe<Scalars['String']>;
  patient: Patient;
  /** Service Ticket Payment Transmissions associated with service tickets */
  serviceTicketPaymentTransmissions?: Maybe<Array<ServiceTicketPaymentTransmission>>;
  serviceTickets: Array<ServiceTicket>;
  state: Scalars['String'];
};

export type SignedS3Url = {
  url: Scalars['String'];
  urlFields?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of SignLegalDocument */
export type SignLegalDocumentInput = {
  type: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignLegalDocument */
export type SignLegalDocumentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  legal_document?: Maybe<LegalDocument>;
  success: Scalars['Boolean'];
};

export type Specialist = {
  acceptedInsurances?: Maybe<Array<Maybe<ContactInsurance>>>;
  /** @deprecated Please use the camelCase version. */
  accepted_insurances?: Maybe<Array<Maybe<ContactInsurance>>>;
  acceptingNewPatients?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  accepting_new_patients?: Maybe<Scalars['Boolean']>;
  acceptsInsurance?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  accepts_insurance?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  companyName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  company_name?: Maybe<Scalars['String']>;
  degrees?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  first_name?: Maybe<Scalars['String']>;
  formerName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  former_name?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  institutions?: Maybe<Array<Maybe<Institution>>>;
  kind: ContactKind;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  last_name?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  middle_name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  officeHours?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  office_hours?: Maybe<Scalars['String']>;
  specialties?: Maybe<Array<Maybe<Specialty>>>;
  suffix?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type Specialty = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SpecialtyType = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type State = {
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  short_name: Scalars['String'];
};

export type StripePlan = Plan & {
  amount?: Maybe<Scalars['Int']>;
  amountInDollars?: Maybe<Scalars['Float']>;
  /** @deprecated Please use the camelCase version. */
  amount_in_dollars?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type StripeSetupIntent = {
  clientSecret?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type Summary = {
  appointment?: Maybe<Appointment>;
  id: Scalars['ID'];
  internalUser?: Maybe<InternalUser>;
  procedureSuggestions?: Maybe<Array<Maybe<MlBillingProcedureSuggestion>>>;
  signedAt?: Maybe<Scalars['DateTime']>;
};

export type Tag = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Task = {
  assignee?: Maybe<InternalUser>;
  body?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt: Scalars['DateTime'];
  deferUntil?: Maybe<Scalars['DateTime']>;
  displayCategory: Scalars['String'];
  id: Scalars['ID'];
  keywords?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nextActions: Scalars['JSON'];
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['ID']>;
  priority: Priority;
  slaDueAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  stateTransitionedAt?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  targetUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TaskFilter = {
  categoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** @deprecated Please use the camelCase version. */
  category_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  /** @deprecated Please use the camelCase version. */
  name: Scalars['String'];
  queueIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** @deprecated Please use the camelCase version. */
  queue_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TextTemplate = {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalUserId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['Int']>;
};

export type TimelinePost = {
  contentType: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  content_type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** @deprecated Please use the camelCase version. */
  created_at: Scalars['DateTime'];
  draft: Scalars['Boolean'];
  id: Scalars['ID'];
  sentAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use the camelCase version. */
  sent_at?: Maybe<Scalars['DateTime']>;
  /**
   * If the internal Todo associated with this post's conversation was reassigned
   * by ML, then it will be returned. If it was manually reassigned or not
   * reassigned, then null is returned.
   */
  todoMessageReassignment?: Maybe<TodoMessageReassignment>;
  updatedAt: Scalars['DateTime'];
  /** @deprecated Please use the camelCase version. */
  updated_at: Scalars['DateTime'];
};


export type TimelinePostTodoMessageReassignmentArgs = {
  onlyReassigned?: Maybe<Scalars['Boolean']>;
};

/** The connection type for TimelinePost. */
export type TimelinePostConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TimelinePostEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TimelinePost>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TimelinePostEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TimelinePost>;
};

export type TodoMessageReassignment = {
  feedback?: Maybe<TodoMessageReassignmentFeedback>;
  id: Scalars['ID'];
  labels: Array<Scalars['String']>;
  newAssignee?: Maybe<Assignee>;
  originalAssignee?: Maybe<Assignee>;
  reason: Scalars['String'];
  wasReassigned: Scalars['Boolean'];
};

export type TodoMessageReassignmentFeedback = {
  feedbackCategory?: Maybe<Scalars['String']>;
  feedbackText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  newAssignee?: Maybe<Assignee>;
  todoMessageReassignment: TodoMessageReassignment;
};

export type Topic = {
  displayName: Scalars['String'];
  /** @deprecated Replaced by displayName */
  displayTitle: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated input type of TransmitServiceTicketTransmission */
export type TransmitServiceTicketTransmissionInput = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TransmitServiceTicketTransmission */
export type TransmitServiceTicketTransmissionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  numberQueued?: Maybe<Scalars['Int']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  serviceTicketTransmissions?: Maybe<Array<ServiceTicketTransmission>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TriggerAppointment */
export type TriggerAppointmentInput = {
  id: Scalars['ID'];
  event: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TriggerAppointment */
export type TriggerAppointmentPayload = {
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TriggerTask */
export type TriggerTaskInput = {
  id: Scalars['ID'];
  event?: Maybe<Scalars['String']>;
  deferUntil?: Maybe<Scalars['DateTime']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TriggerTask */
export type TriggerTaskPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};


/** Autogenerated input type of UpdateAddress */
export type UpdateAddressInput = {
  id?: Maybe<Scalars['ID']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateCode?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAddress */
export type UpdateAddressPayload = {
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateAppointment */
export type UpdateAppointmentInput = {
  id: Scalars['ID'];
  comments?: Maybe<Scalars['String']>;
  isBillingReviewed?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAppointmentInventory */
export type UpdateAppointmentInventoryInput = {
  id: Scalars['ID'];
  blocked?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAppointmentInventory */
export type UpdateAppointmentInventoryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  inventory?: Maybe<AppointmentInventory>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateAppointment */
export type UpdateAppointmentPayload = {
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateContact */
export type UpdateContactInput = {
  id: Scalars['ID'];
  kind?: Maybe<ContactKind>;
  statusId?: Maybe<Scalars['ID']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  incompleteReason?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  formerName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  degrees?: Maybe<Scalars['String']>;
  languagesSpoken?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  officeHours?: Maybe<Scalars['String']>;
  availability?: Maybe<Rating>;
  expertise?: Maybe<Rating>;
  personality?: Maybe<Rating>;
  customWarning?: Maybe<Scalars['String']>;
  otherNotes?: Maybe<Scalars['String']>;
  noPhysicalLocation?: Maybe<Scalars['Boolean']>;
  acceptingNewPatients?: Maybe<Scalars['Boolean']>;
  acceptsInsurance?: Maybe<Scalars['Boolean']>;
  npi?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  contactInsurancesAttributes?: Maybe<Array<Maybe<ContactInsuranceAttributes>>>;
  nonAcceptedContactInsurancesAttributes?: Maybe<Array<Maybe<ContactInsuranceAttributes>>>;
  insuranceNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  insuranceNotes?: Maybe<Scalars['String']>;
  phoneNumbersAttributes?: Maybe<Array<Maybe<PhoneNumberAttributes>>>;
  emailsAttributes?: Maybe<Array<Maybe<EmailAttributes>>>;
  addressAttributes?: Maybe<AddressAttributes>;
  specialtyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  institutionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  diagnosticGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isInternal?: Maybe<Scalars['Boolean']>;
  isPartner?: Maybe<Scalars['Boolean']>;
  isQuality?: Maybe<Scalars['Boolean']>;
  isElectronicallyIntegrated?: Maybe<Scalars['Boolean']>;
  hasPreferredAccess?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateContact */
export type UpdateContactPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateImproperlyClosedServiceTicketOrder */
export type UpdateImproperlyClosedServiceTicketOrderInput = {
  id: Scalars['ID'];
  state: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateImproperlyClosedServiceTicketOrder */
export type UpdateImproperlyClosedServiceTicketOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketImproperlyClosedOrder: ServiceTicketImproperlyClosedOrder;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateInsurance */
export type UpdateInsuranceInput = {
  id: Scalars['ID'];
  copay?: Maybe<Scalars['Int']>;
  coinsurance?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  groupno: Scalars['String'];
  subscriberNumber?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['String']>;
  insuranceCarrierId?: Maybe<Scalars['ID']>;
  insuranceTypeId?: Maybe<Scalars['ID']>;
  insuranceNetworkId?: Maybe<Scalars['ID']>;
  insuranceRelationshipTypeId?: Maybe<Scalars['ID']>;
  holderFirstName?: Maybe<Scalars['String']>;
  holderLastName?: Maybe<Scalars['String']>;
  holderDob?: Maybe<Scalars['String']>;
  holderAddress1?: Maybe<Scalars['String']>;
  holderAddress2?: Maybe<Scalars['String']>;
  holderCity?: Maybe<Scalars['String']>;
  holderState?: Maybe<Scalars['String']>;
  holderZipCode?: Maybe<Scalars['String']>;
  holderPhoneNumber?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  insuranceImage?: Maybe<S3Attributes>;
  mlOcrNeedsHumanReview?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateInsurance */
export type UpdateInsurancePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateInternalUserPreferences */
export type UpdateInternalUserPreferencesInput = {
  videoNotificationEnabled?: Maybe<Scalars['Boolean']>;
  licensingBodies?: Maybe<Array<Scalars['String']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateInternalUserPreferences */
export type UpdateInternalUserPreferencesPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  internalUser?: Maybe<InternalUser>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateLabOrder */
export type UpdateLabOrderInput = {
  id: Scalars['ID'];
  measurementOrderTypeSourceId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateLabOrder */
export type UpdateLabOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  labOrder?: Maybe<LabOrder>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatient */
export type UpdatePatientInput = {
  id: Scalars['ID'];
  officeId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePatient */
export type UpdatePatientPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePendingDirectSignupAccount */
export type UpdatePendingDirectSignupAccountInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePendingDirectSignupAccount */
export type UpdatePendingDirectSignupAccountPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<DirectSignupError>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateProcedureInteraction */
export type UpdateProcedureInteractionInput = {
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  procedureSummary?: Maybe<Scalars['String']>;
  procedureTypeId?: Maybe<Scalars['ID']>;
  formData?: Maybe<Scalars['JSON']>;
  resultsInterpretation?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProcedureInteraction */
export type UpdateProcedureInteractionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  roleIds?: Maybe<Array<Scalars['ID']>>;
  /** deprecated, please use the camelCase version */
  role_ids?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProfile */
export type UpdateProfilePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  profile: InternalUser;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramCarePlan */
export type UpdateProgramCarePlanInput = {
  id: Scalars['ID'];
  carePlan: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProgramCarePlan */
export type UpdateProgramCarePlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  programCarePlan?: Maybe<ProgramCarePlan>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramEnrollmentCareTeam */
export type UpdateProgramEnrollmentCareTeamInput = {
  id: Scalars['ID'];
  careGiverIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProgramEnrollmentCareTeam */
export type UpdateProgramEnrollmentCareTeamPayload = {
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramEnrollment */
export type UpdateProgramEnrollmentInput = {
  id: Scalars['ID'];
  programPhaseId?: Maybe<Scalars['ID']>;
  outreachComment?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  preferredTime?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProgramEnrollment */
export type UpdateProgramEnrollmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  programEnrollment?: Maybe<ProgramEnrollment>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramForAppointment */
export type UpdateProgramForAppointmentInput = {
  appointmentId: Scalars['ID'];
  programId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProgramForAppointment */
export type UpdateProgramForAppointmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  programEnrollment?: Maybe<ProgramEnrollment>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramVisit */
export type UpdateProgramVisitInput = {
  id: Scalars['ID'];
  summaryOfVisit?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['JSON']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProgramVisit */
export type UpdateProgramVisitPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  programVisit?: Maybe<ProgramVisit>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateServiceTicket */
export type UpdateServiceTicketInput = {
  id: Scalars['ID'];
  processingInstructions?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateServiceTicketItem */
export type UpdateServiceTicketItemInput = {
  id: Scalars['ID'];
  billingMethod?: Maybe<Scalars['String']>;
  selfPayReason?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServiceTicketItem */
export type UpdateServiceTicketItemPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketItem?: Maybe<ServiceTicketItem>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateServiceTicket */
export type UpdateServiceTicketPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateStripePaymentMethodNickname */
export type UpdateStripePaymentMethodNicknameInput = {
  stripePaymentMethodId: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateStripePaymentMethodNickname */
export type UpdateStripePaymentMethodNicknamePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  paymentMethod?: Maybe<PaymentMethod>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  id: Scalars['ID'];
  priority?: Maybe<Priority>;
  assigneeIdent?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTask */
export type UpdateTaskPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of UpdateVoluntaryAlignmentOffer */
export type UpdateVoluntaryAlignmentOfferInput = {
  id: Scalars['ID'];
  followUpAt?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  offerMethod?: Maybe<Scalars['String']>;
  offeredAt?: Maybe<Scalars['DateTime']>;
  offeredById?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  alignmentDocument?: Maybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateVoluntaryAlignmentOffer */
export type UpdateVoluntaryAlignmentOfferPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

/** Autogenerated input type of UpsertMlFeedbackTodoMessageReassignment */
export type UpsertMlFeedbackTodoMessageReassignmentInput = {
  todoMessageReassignmentId: Scalars['ID'];
  feedbackCategory?: Maybe<Scalars['String']>;
  feedbackText?: Maybe<Scalars['String']>;
  newAssigneeId?: Maybe<Scalars['ID']>;
  newAssigneeType?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpsertMlFeedbackTodoMessageReassignment */
export type UpsertMlFeedbackTodoMessageReassignmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  todoMessageReassignmentFeedback?: Maybe<TodoMessageReassignmentFeedback>;
};

export type VisitMetadata = {
  code?: Maybe<Scalars['Int']>;
  hostId?: Maybe<Scalars['String']>;
  meetingUuid?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type VoluntaryAlignmentOffer = {
  alignmentDocumentS3Link?: Maybe<Scalars['String']>;
  documents: Array<Document>;
  followUpAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  offerMethod: Scalars['String'];
  offeredAt?: Maybe<Scalars['DateTime']>;
  offeredBy?: Maybe<InternalUser>;
  patient: Patient;
  reason?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
};

export type WhitelistedEmployee = {
  b2bCompany: B2bCompany;
  dependents: Array<ScrubbedWhitelistedEmployee>;
  employeeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  registered: Scalars['Boolean'];
  workEmail?: Maybe<Scalars['String']>;
};

export type Zoom = {
  /**
   * A signature for use with the Web SDK. Allows a user to join a meeting.
   * https://marketplace.zoom.us/docs/sdk/native-sdks/web/essential/signature
   */
  signature: Scalars['String'];
  visitMetadata: VisitMetadata;
};


export type ZoomSignatureArgs = {
  meetingId: Scalars['String'];
};


export type ZoomVisitMetadataArgs = {
  meetingId: Scalars['String'];
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { featureFlags: Array<Maybe<string>>, profile?: Maybe<{ id: string, displayName: string, email: string, lastName: string, preferredRole?: Maybe<{ name: string }>, nonPreferredRoles?: Maybe<Array<Maybe<{ id: number, name: string }>>>, roles?: Maybe<Array<Maybe<{ id: number }>>>, homeOffice?: Maybe<{ id: string }>, serviceArea?: Maybe<{ id: string, serviceAreaGroup?: Maybe<{ id: string, serviceAreas?: Maybe<Array<Maybe<{ id: string }>>> }> }> }> };

export type UpdateProfileMutationVariables = Exact<{
  roleIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateProfileMutation = { updateProfile?: Maybe<{ success: boolean, errors?: Maybe<any>, profile: { id: string, roles?: Maybe<Array<Maybe<{ id: number }>>> } }> };


export const GetProfileDocument = gql`
    query GetProfile {
  profile {
    id
    displayName
    email
    lastName
    preferredRole {
      name
    }
    nonPreferredRoles {
      id
      name
    }
    roles {
      id
    }
    homeOffice {
      id
    }
    serviceArea {
      id
      serviceAreaGroup {
        id
        serviceAreas {
          id
        }
      }
    }
  }
  featureFlags
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, baseOptions);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, baseOptions);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($roleIds: [ID!]!) {
  updateProfile(input: {roleIds: $roleIds}) {
    success
    errors
    profile {
      id
      roles {
        id
      }
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;