import { useEffect } from 'react';
import { Alert, CircularProgress, Grid, styled } from '@mui/material';
import { OAuthCallbackRoute, PrivateRoute, useAuthentication } from '@onemedical/auth';
import MicroFrontend from '@onemedical/micro-frontend';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';

import { useGetProfileQuery } from '../generated';
import PendoHelper from '../helpers/pendo';
import mixpanel from '../mixpanel';
import NavBar from './NavBar';

declare global {
  interface Window {
    Cypress: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

const hostedApps = [
  {
    id: 'admin',
    path: '/admin',
    host: process.env.REACT_APP_ADMIN_ASSETS_URL!,
  },
  {
    id: 'schedule',
    path: '/schedule',
    host: process.env.REACT_APP_SCHEDULE_ASSETS_URL!,
  },
  {
    id: 'panel-management',
    path: '/panel-management',
    host: process.env.REACT_APP_PANEL_MANAGEMENT_ASSETS_URL!,
  },
  {
    id: 'reconciliations',
    path: '/reconciliations',
    host: process.env.REACT_APP_RECONCILIATIONS_ASSETS_URL!,
  },
];

const StyledGrid = styled(Grid)(() => ({
  height: '100vh',
  width: '100%',
}));

function App() {
  const [, { authenticating, authenticated, error: authError }] = useAuthentication();
  const ldClient = useLDClient();
  const { data, loading } = useGetProfileQuery({
    skip: !authenticated,
    onCompleted: (profileData) => {
      if (profileData) {
        const pendo = new PendoHelper();
        pendo.initialize(profileData);
        if (profileData.profile) {
          mixpanel.identify(profileData.profile.id);
        }
      }
    },
  });

  useEffect(() => {
    if (data && data.profile && ldClient) {
      ldClient.identify({ key: data.profile.id });
    }
  }, [data, ldClient]);

  const launchDarklyIsLoaded = !!ldClient || window.Cypress; // don't delay specs
  const showLoadingScreen = authenticating || authError || loading || !launchDarklyIsLoaded;

  if (showLoadingScreen) {
    return (
      <StyledGrid container>
        <Grid item container justifyContent="center" alignItems="center" direction="column">
          {authError ? (
            <Grid item>
              <Alert severity="error">{authError}</Alert>
            </Grid>
          ) : (
            <>
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <div>{authenticating && 'Checking credentials...'}</div>
              </Grid>
            </>
          )}
        </Grid>
      </StyledGrid>
    );
  }

  return (
    <BrowserRouter>
      <OAuthCallbackRoute />
      {/* legacy routes used by onelife (to be deleted) */}
      <Route
        path={['/tasks', '/contacts', '/contact', '/provider-directory']}
        render={({ location }) => <Redirect to={`/admin${location.pathname}${location.search}`} />}
      />
      <Route
        path={['/appointments', '/phlebotomy-visits', '/provider-schedule']}
        render={({ location }) => (
          <Redirect to={`/schedule${location.pathname}${location.search}`} />
        )}
      />
      {/* end of legacy routes */}
      <Route exact path="/">
        {authenticated && <Redirect to="/admin" />}
      </Route>
      <PrivateRoute path="/" component={NavBar} />
      {hostedApps.map(({ id, path, host }) => (
        <Route
          key={id}
          path={path}
          render={({ history }) => (
            <MicroFrontend
              id={id}
              host={host}
              history={history}
              containedAppProps={data} // used to trigger a refresh on profile change
              onError={(error) => {
                console.error(`Failed to load '${id}' micro-frontend`, error); // eslint-disable-line no-console
              }}
            >
              <StyledGrid container justifyContent="center" alignItems="center" direction="column">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </StyledGrid>
            </MicroFrontend>
          )}
        />
      ))}
    </BrowserRouter>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID!,
  options: {
    sendEventsOnlyForVariation: true,
    ...(window.Cypress && { streaming: !window.Cypress }),
  },
})(App);
