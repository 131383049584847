import React, { useState } from 'react';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Grid, MenuItem, styled } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import Logo from '../components/Logo';
import roles from '../data/roles';
import { ServiceArea, useGetProfileQuery } from '../generated';
import NavButton from './NavButton';
import NavMenu from './NavMenu';
import PatientSearchBox from './PatientSearchBox';
import UserMenu from './UserMenu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  padding: theme.spacing(0, 2),
  minWidth: '1155px',
  zIndex: theme.zIndex.tooltip,
}));

const StyledGrid = styled(Grid)(() => ({
  height: '40px',
  overflow: 'hidden',
  flexWrap: 'nowrap',
}));

const NavBar = () => {
  const { panelManagementMvp, providerApptInventoryUi } = useFlags();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openAppointmentsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { loading, data } = useGetProfileQuery();
  if (loading || !data) return null;
  const { profile, featureFlags } = data;
  const oneLifeUrl = process.env.REACT_APP_ONELIFE_URL;
  const serviceAreas = (profile?.serviceArea?.serviceAreaGroup?.serviceAreas ||
    []) as ServiceArea[];
  const serviceAreasToUrl = (serviceArea: ServiceArea[] | undefined) => {
    serviceArea?.map((sa) => `service_area[]=${sa.id}-ServiceArea`).join('&');
  };
  const todaysDate = moment().format('YYYY-MM-DD');
  const isAdmin = Boolean(profile?.roles?.map((r) => r?.id).includes(roles.ADMIN));

  return (
    <StyledAppBar position="sticky">
      <StyledGrid container alignItems="center">
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <NavButton color="inherit" component={NavLink} to="/admin/tasks" data-cy="tasks-nav">
            Tasks
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            onClick={openAppointmentsMenu}
            endIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            Appointments
          </NavButton>
          <NavMenu
            id="simple-menu"
            anchorEl={anchorEl}
            disableScrollLock
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={NavLink} to="/schedule/appointments" data-cy="appointments-nav">
              Appointments
            </MenuItem>
            <MenuItem
              component={NavLink}
              to="/schedule/appointment-scanner"
              data-cy="appointment-scanner-nav"
            >
              Appointment Scanner
            </MenuItem>
            {featureFlags.includes('get_care_mass_cancellations') &&
              profile?.roles?.some((r) => r && r.id === roles.MASS_CANCELLATION) && (
                <MenuItem
                  component={NavLink}
                  to="/schedule/appointment-cancellation-requests"
                  data-cy="appointment-cancellation-requests-nav"
                >
                  Appointment Cancellations
                </MenuItem>
              )}
            <MenuItem
              component={NavLink}
              to="/schedule/provider-schedule"
              data-cy="provider-schedule-nav"
            >
              Provider Schedule
            </MenuItem>
            {providerApptInventoryUi && (
              <MenuItem
                component={NavLink}
                to="/schedule/provider-appt-inventory"
                data-cy="provider-appt-inventory-nav"
              >
                Provider Appointment Inventory
              </MenuItem>
            )}
            {featureFlags.includes('schedule_ui_phleb_visits') ? (
              <MenuItem
                component={NavLink}
                to="/schedule/phlebotomy-visits"
                data-cy="phlebotomy-visits-nav"
              >
                Phlebotomy Visits
              </MenuItem>
            ) : (
              <MenuItem
                component="a"
                href={`${oneLifeUrl}/admin/offices/${profile?.homeOffice?.id}/walkin_visits?date=${todaysDate}`}
                target="_self"
                data-cy="walk-in-visits-nav"
              >
                Walk In Visits
              </MenuItem>
            )}
            <MenuItem
              component="a"
              href={`${oneLifeUrl}/admin/appointments/unregistered`}
              data-cy="unregistered-patients-nav"
            >
              Unregistered Patients
            </MenuItem>
            <MenuItem
              component="a"
              href={`${oneLifeUrl}/admin/offices/${profile?.homeOffice?.id}/schedules?date=${todaysDate}`}
              data-cy="office-schedule-nav"
            >
              Office Schedule
            </MenuItem>
            <MenuItem
              component="a"
              href={`${oneLifeUrl}/admin/video_appointments`}
              data-cy="virtual-visits-nav"
            >
              Virtual Visits
            </MenuItem>
          </NavMenu>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            href={`${oneLifeUrl}/admin/inbox?${serviceAreasToUrl(serviceAreas)}`}
            data-cy="docs-nav"
          >
            Docs
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton color="inherit" href={`${oneLifeUrl}/fax/send_fax`} data-cy="faxes-nav">
            Faxes
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            component={NavLink}
            to="/admin/contacts"
            data-cy="contacts-nav"
          >
            Contacts
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            component={NavLink}
            to="/admin/provider-directory"
            data-cy="providers-nav"
          >
            Providers
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            href={process.env.REACT_APP_TEMPLATE_MANAGER_URL}
            data-cy="Template Manager"
          >
            Template Manager
          </NavButton>
        </Grid>
        {panelManagementMvp && (
          <Grid item>
            <NavButton
              color="inherit"
              component={NavLink}
              to="/panel-management"
              data-cy="panel-management-nav"
            >
              Panel Management
            </NavButton>
          </Grid>
        )}
        <Box flexGrow={1} />
        <Grid item sx={{ marginRight: 1 }}>
          <PatientSearchBox isAdmin={isAdmin} data-cy="patient-search-nav" />
        </Grid>
        <Grid item>
          <UserMenu data-cy="profile-name-nav" />
        </Grid>
      </StyledGrid>
    </StyledAppBar>
  );
};

export default NavBar;
